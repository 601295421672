import { ethers } from "ethers";

const state = () => ({
    itemsContract: null,
});

const mutations = {
    initializeContract(state, contract) {
        state.itemsContract = contract;
    },
};

const actions = {
    boot({ commit, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/items.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(
            contractAddress,
            abi,
            signer || defaultSigner
        );

        commit("initializeContract", contract);
    },

    async getCropsBalance({ state, rootState }) {
        const contract = state.itemsContract;
        const cropTypes = rootState.content.cropTypes;
        const account = rootState.user.storedAccount;

        const accounts = new Array(cropTypes.length).fill(account);
        const seedIds = [];
        for (let i = 1; i <= cropTypes.length; i++) {
            seedIds.push(i);
        }

        const cropsBalances = await contract.balanceOfBatch(accounts, seedIds);

        const crops = cropsBalances.map((balance, index) => {
            return {
                tokenId: index,
                balance: parseFloat(balance),
            };
        });

        return crops;
    },

    async getItemsBalances({ state, rootState }) {
        const contract = state.itemsContract;
        const itemsDataRaw = rootState.content.itemsDataRaw;
        const account = rootState.user.storedAccount;

        const accounts = new Array(itemsDataRaw.length).fill(account);
        const itemIds = itemsDataRaw.map((i) => i.tokenId);

        const itemsBalances = await contract.balanceOfBatch(accounts, itemIds);

        const items = itemsBalances.map((balance, index) => {
            return {
                tokenId: itemsDataRaw[index].tokenId,
                balance: parseFloat(balance),
            };
        });

        return items;
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};
