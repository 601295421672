<template>
    <div class="item-button">
        <div :style="itemStyle" class="item-button-icon"></div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "ItemDisplay",

    components: {},

    props: {
        itemType: {
            type: String,
            default: "tomato",
        },
    },

    data() {
        return {};
    },

    methods: {},

    computed: {
        ...mapState("content", ["itemsData"]),

        itemStyle() {
            const itemData = this.itemsData[this.itemType];

            const image = require("../../../public/images/items.png");

            let [xOffset, yOffset] = [0, 0];

            const imageIndex = itemData.tokenId - 23;
            const itemStyle = {
                background: `url(${image})`,
                backgroundPositionY: `-${imageIndex * 64 + yOffset}px`,
                backgroundPositionX: `${xOffset}px`,
            };

            return { ...itemStyle };
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.item-button {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0.25rem;
    align-items: center;

    background: url("../../../public/images/roundContainer.png");
    background-size: 100% 100%;
    height: 6.5rem;
    width: 6.5rem;
    min-width: 6.5rem;
    min-height: 6.5rem;

    position: relative;

    &:hover {
        filter: brightness(1.1);
    }
    cursor: pointer;

    &-count {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
    }

    &-icon {
        height: 4rem;
        width: 4rem;
        image-rendering: pixelated;
        background: brown;
        transform-origin: 32px 64px;

        &-active {
            animation: jiggle 0.5s ease-in-out infinite;
        }
    }

    &-plank {
        position: absolute;
        bottom: -0.5rem;
        width: 6rem;
        height: 2rem;

        border-image: url("../../../public/images/reelBg.png") 6 fill repeat;
        border-image-width: 1.5rem;

        display: flex;
        justify-content: center;
        align-items: center;

        p {
            margin-bottom: 0.25rem;
        }
    }
}

@keyframes jiggle {
    0% {
        transform: scale(2) rotate(0deg);
    }
    50% {
        transform: scale(2) rotate(5deg);
    }
    100% {
        transform: scale(2) rotate(0deg);
    }
}
</style>
