import { ethers } from "ethers";
import { createNotification } from "@/libs/storeHelper";

const state = () => ({
    faucetContract: null,
});

const mutations = {
    initializeContract(state, contract) {
        state.faucetContract = contract;
    },
};

const actions = {
    boot({ commit, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/faucet.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(
            contractAddress,
            abi,
            signer || defaultSigner
        );

        commit("initializeContract", contract);
    },

    async requestTokens({ state, dispatch, rootState }) {
        const contract = state.faucetContract;

        const tx = await contract.requestTokens();

        createNotification({
            dispatch,
            type: "warn",
            message: `Tx: Requesting tokens`,
            id: `${tx.hash}`,
        });

        await tx.wait();

        dispatch("site/notifications/remove", tx.hash, { root: true });

        createNotification({
            dispatch,
            message: `Received tokens and seeds. Good Luck!`,
            duration: 5000,
        });
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};
