<template>
    <div class="game-frame" ref="game">
        <div class="game-frame-house">
            <img
                style="width: 100%; height: 100%"
                src="../../../public/images/house.png"
            />

            <MapBanner
                @clicked="setCurrentModal('referral')"
                :text="$t('menu.quests')"
                :position="{ x: 800, y: 90 }"
            ></MapBanner>

            <Character
                @clicked="setCurrentModal('farmer')"
                :hasBanner="true"
                :character="'farmer'"
                :position="{ x: 785, y: 270 }"
            ></Character>
            <Character
                @clicked="setCurrentModal('gardener')"
                :hasBanner="true"
                :character="'gardener'"
                :position="{ x: 337, y: 174 }"
            ></Character>

            <Character
                v-if="isTestnet"
                @clicked="setCurrentModal('faucet')"
                :character="'faucet'"
                :position="{ x: 340, y: 180 }"
            ></Character>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    name: "HouseScreen",

    components: {},

    props: {},

    data() {
        return {};
    },

    mounted() {
        const panzoomContainer = this.$refs.game;
        this.initializePanzoom(panzoomContainer);
        this.resetZoom();
    },

    beforeDestroy() {
        this.destroyPanzoom();
        this.resetZoom();
    },

    methods: {
        ...mapActions("site", ["setCurrentModal"]),
        ...mapActions("panzoom", [
            "initializePanzoom",
            "destroyPanzoom",
            "zoomTo",
            "resetZoom",
        ]),
    },

    computed: {
        ...mapState("user", ["chainId"]),

        isTestnet() {
            return this.chainId == "168587773";
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.game-frame {
    width: 100%;
    height: 100%;
    border-radius: 0.25rem;

    &-house {
        width: 960px;
        height: 480px;
        position: absolute;
        border-image: url("../../../public/images/itemBg.png") 6 fill repeat;
        border-image-width: 1rem;
        border-image-outset: 0.5rem;

        position: absolute;
        top: calc(50% - 240px);
        left: calc(50% - 480px);
    }
}
</style>
