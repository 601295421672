const { axios } = require("../libs/axios");

/*export async function getContentCache() {
    try {
        const result = await axios.get("/api/content/packed");
        const data = result.data;
        return data;
    } catch (err) {
        return {};
    }
}*/

/// TODO: Placeholder until the server goes live
export async function getContentCache() {
    try {
        const data = {
            items,
            crops,
        };
        return data;
    } catch (err) {
        return {};
    }
}

const crops = [
    {
        name: "wheat",
        tokenId: 1,
        imageIndex: 1,
        price: 1,
        life: 8640,
        produceId: 1001,
        reward: 10,
        rarity: "common",
        description: "A basic ingredient for bakers and brewers in the realm.",
        seedDescription:
            "Seed for planting wheat, essential for bread and brew crafting.",
    },
    {
        name: "tomato",
        tokenId: 2,
        imageIndex: 2,
        price: 1,
        life: 8640,
        produceId: 1002,
        reward: 10,
        rarity: "common",
        description: "Essential for cooking sauces and stews for adventurers.",
        seedDescription:
            "Seed for growing tomatoes, perfect for concocting hearty meals.",
    },
    {
        name: "carrot",
        tokenId: 3,
        imageIndex: 3,
        price: 1,
        life: 8640,
        produceId: 1003,
        reward: 10,
        rarity: "common",
        description:
            "Crunchy and sweet, perfect for a quick snack or a stew ingredient.",
        seedDescription:
            "Seed for cultivating carrots, known for their sweet crunch.",
    },
    {
        name: "corn",
        tokenId: 4,
        imageIndex: 4,
        price: 1,
        life: 8640,
        produceId: 1004,
        reward: 10,
        rarity: "common",
        description: "Versatile for meals and snacks, favored by travelers.",
        seedDescription:
            "Seed for sowing corn, yields versatile grains for various dishes.",
    },
    {
        name: "pumpkin",
        tokenId: 5,
        imageIndex: 5,
        price: 3,
        life: 12960,
        produceId: 1005,
        reward: 18,
        rarity: "uncommon",
        description: "Good for pies and magical brews, a fall favorite.",
        seedDescription:
            "Seed for pumpkin, crucial for fall festivals and magical concoctions.",
    },
    {
        name: "chilli",
        tokenId: 6,
        imageIndex: 6,
        price: 3,
        life: 12960,
        produceId: 1006,
        reward: 18,
        rarity: "uncommon",
        description: "Adds fire to any dish, handle with care.",
        seedDescription:
            "Seed for growing chilli, ignites dishes with fiery spice.",
    },
    {
        name: "radish",
        tokenId: 7,
        imageIndex: 7,
        price: 3,
        life: 12960,
        produceId: 1007,
        reward: 18,
        rarity: "uncommon",
        description: "A peppery root that's popular in salads and potions.",
        seedDescription:
            "Seed for radish, brings a peppery kick to dishes and brews.",
    },
    {
        name: "celery",
        tokenId: 8,
        imageIndex: 8,
        price: 5,
        life: 17280,
        produceId: 1008,
        reward: 24,
        rarity: "rare",
        description: "Crunchy and refreshing, used in healing potions.",
        seedDescription:
            "Seed for celery, grows into a crunchy snack with refreshing powers.",
    },
    {
        name: "broccoli",
        tokenId: 9,
        imageIndex: 9,
        price: 5,
        life: 17280,
        produceId: 1009,
        reward: 24,
        rarity: "rare",
        description: "Valued for its restorative properties in cooking.",
        seedDescription:
            "Seed for broccoli, sprouts into a vegetable with healing virtues.",
    },
    {
        name: "cabbage",
        tokenId: 10,
        imageIndex: 10,
        price: 10,
        life: 21600,
        produceId: 1010,
        reward: 45,
        rarity: "epic",
        description: "A versatile veggie for hearty meals and fortification.",
        seedDescription:
            "Seed for cabbage, yields leafy greens for fortifying meals.",
    },
    {
        name: "berry",
        tokenId: 11,
        imageIndex: 11,
        price: 25,
        life: 21600,
        produceId: 1011,
        reward: 45,
        rarity: "epic",
        description: "Sweet and mystical, used in many elixirs.",
        seedDescription:
            "Seed for berries, cultivates sweet fruits for enchanting elixirs.",
    },
    {
        name: "grapes",
        tokenId: 12,
        imageIndex: 12,
        price: 10,
        life: 25920,
        produceId: 1012,
        reward: 140,
        rarity: "legendary",
        description:
            "Favored by nobility, used in fine dining and rare potions.",
        seedDescription:
            "Seed for grapes, grows vines for the fruits of nobility and fine wines.",
    },

    {
        name: "banana",
        tokenId: 13,
        imageIndex: 9,
        life: 8640,
        premium: true,
        reward: 500,
        rarity: "common",
        description:
            "A staple of tropical diets, energizing adventurers and locals alike.",
        seedDescription:
            "Seed for bananas, grows into lush trees bearing the fruit of energy and nutrition.",
    },
    {
        name: "mango",
        tokenId: 14,
        imageIndex: 1,
        life: 8640,
        premium: true,
        reward: 500,
        rarity: "common",
        description:
            "Exotic and juicy, sought after for its sweet, revitalizing essence.",
        seedDescription:
            "Seed for mangoes, cultivating trees that produce the golden treasure of the tropics.",
    },
    {
        name: "avocado",
        tokenId: 15,
        imageIndex: 8,
        life: 8640,
        premium: true,
        reward: 500,
        rarity: "common",
        description:
            "Creamy and nutritious, a favorite for health potions and gourmet meals.",
        seedDescription:
            "Seed for avocados, sprouts into trees with fruits beloved for their healing fats.",
    },
    {
        name: "pineapple",
        tokenId: 16,
        imageIndex: 4,
        life: 12960,
        premium: true,
        reward: 900,
        rarity: "uncommon",
        description:
            "Tart and sweet, used in potions for strength and cooking for exotic flavor.",
        seedDescription:
            "Seed for pineapples, yields spiky plants with the fruit of warriors and explorers.",
    },
    {
        name: "blueberry",
        tokenId: 17,
        imageIndex: 3,
        life: 12960,
        premium: true,
        reward: 900,
        rarity: "uncommon",
        description:
            "Tiny yet powerful, imbued with magic to boost health and vitality.",
        seedDescription:
            "Seed for blueberries, grows into bushes laden with the berries of life.",
    },
    {
        name: "artichoke",
        tokenId: 18,
        imageIndex: 11,
        life: 12960,
        premium: true,
        reward: 900,
        rarity: "uncommon",
        description:
            "Mystical in appearance and taste, enhances magical resistance when consumed.",
        seedDescription:
            "Seed for artichokes, produces thistle-like plants with magical properties.",
    },
    {
        name: "papaya",
        tokenId: 19,
        imageIndex: 2,
        life: 17280,
        premium: true,
        reward: 1200,
        rarity: "rare",
        description:
            "Sweet and healing, papayas offer restoration and are prized in medicinal brews.",
        seedDescription:
            "Seed for papaya, grows tropical trees with the fruit of regeneration.",
    },
    {
        name: "fig",
        tokenId: 20,
        imageIndex: 10,
        life: 17280,
        premium: true,
        reward: 1200,
        rarity: "rare",
        description:
            "Ancient and sweet, figs are known to unlock mystic energies and wisdom.",
        seedDescription:
            "Seed for figs, cultivates trees that bear the fruit of knowledge and power.",
    },
    {
        name: "lichi",
        tokenId: 21,
        imageIndex: 6,
        life: 21600,
        premium: true,
        reward: 2250,
        rarity: "epic",
        description:
            "Exotic and fragrant, lychees are coveted for their burst of sweet energy.",
        seedDescription:
            "Seed for lychees, grows trees with fruits that enchant the senses and invigorate the soul.",
    },
    {
        name: "lavender",
        tokenId: 22,
        imageIndex: 5,
        life: 21600,
        premium: true,
        reward: 2250,
        rarity: "epic",
        description:
            "Calming and aromatic, used in spells for peace and potions for rest.",
        seedDescription:
            "Seed for lavender, yields fragrant plants with the power to soothe and heal.",
    },
    {
        name: "dragonfruit",
        tokenId: 23,
        imageIndex: 7,
        life: 25920,
        premium: true,
        reward: 7000,
        rarity: "legendary",
        description:
            " Unique and refreshing, known for their striking appearance and sweet taste.",
        seedDescription:
            "Produces vibrant cacti with nutrient-rich, flavorful fruits.",
    },
];
const items = [
    {
        name: "lesserGrowthElixir",
        tokenId: 24,
        price: 1,
        rarity: "common",
        contract: "watercan",
        tKey: "growth",
        value: 6,
        uses: 4,
    },
    {
        name: "growthElixir",
        tokenId: 25,
        price: 2,
        rarity: "uncommon",
        contract: "watercan",
        tKey: "growth",
        value: 12,
        uses: 4,
    },
    {
        name: "greaterGrowthElixir",
        tokenId: 26,
        price: 3,
        rarity: "rare",
        contract: "watercan",
        tKey: "growth",
        value: 24,
        uses: 4,
    },

    {
        name: "basicFertilizer",
        tokenId: 27,
        price: 1,
        rarity: "rare",
        contract: "plotModifier",
        tKey: "fertilizer",
        value: 10,
        uses: 3,
    },
    {
        name: "advancedFertilizer",
        tokenId: 28,
        price: 2,
        rarity: "epic",
        contract: "plotModifier",
        tKey: "fertilizer",
        value: 25,
        uses: 3,
    },
    {
        name: "superFertilizer",
        tokenId: 29,
        price: 3,
        rarity: "legendary",
        contract: "plotModifier",
        tKey: "fertilizer",
        value: 50,
        uses: 3,
    },

    {
        name: "basicPesticide",
        tokenId: 30,
        price: 1,
        rarity: "common",
        contract: "plotModifier",
        tKey: "pesticide",
        value: 2,
        uses: 6,
    },
    {
        name: "advancedPesticide",
        tokenId: 31,
        price: 2,
        rarity: "rare",
        contract: "plotModifier",
        tKey: "pesticide",
        value: 3,
        uses: 6,
    },

    {
        name: "woodenChest",
        tokenId: 32,
        price: 1,
        rarity: "uncommon",
        contract: "woodenChest",
    },
    {
        name: "goldenChest",
        tokenId: 33,
        price: 2,
        rarity: "legendary",
        contract: "woodenChest",
    },
];
