import { getContentCache } from "@/api/content";

const state = {
    cropTypes: [],
    cropsData: undefined,
    cropsDataRaw: [],

    itemTypes: [],
    itemsData: [],
    itemsDataRaw: [],

    boostsData: [],
};

const mutations = {
    initializeCrops(state, names) {
        state.cropTypes = names;
    },

    initializeCropsData(state, cropsData) {
        state.cropsData = cropsData;
    },

    initializeCropsDataRaw(state, cropsDataRaw) {
        state.cropsDataRaw = cropsDataRaw;
    },

    setItemsData(state, itemsData) {
        state.itemsData = itemsData;
    },

    initializeItems(state, names) {
        state.itemTypes = names;
    },

    initializeItemsDataRaw(state, itemsDataRaw) {
        state.itemsDataRaw = itemsDataRaw;
    },

    initializeBoostsData(state, boostsData) {
        state.boostsData = boostsData;
    },
};

const actions = {
    async boot({ dispatch }) {
        await dispatch("initializeContent", {});
    },

    async initializeContent({ commit, rootState, state }) {
        const content = await getContentCache();

        const cropsRawData = content.crops || [];
        const itemsRawData = content.items || [];

        const crops = cropsRawData.map((c) => c.name);
        const cropsData = {};

        const items = itemsRawData.map((i) => i.name);
        const itemsData = {};

        cropsRawData.forEach((c) => {
            cropsData[c.name] = {
                tokenId: c.tokenId,
                imageIndex: c.imageIndex,
                rarity: c.rarity,
                life: c.life,
                produceId: c.produceId,
                reward: c.reward,
                name: c.name,
                description: c.description,
                seedDescription: c.seedDescription,
            };
        });

        itemsRawData.forEach((i) => {
            itemsData[i.name] = {
                tokenId: i.tokenId,
                name: i.name,
                description: i.description,
                effect: i.effect,
                rarity: i.rarity,
                contract: i.contract,
                uses: i.uses,
                value: i.value,
                tKey: i.tKey,
            };
        });

        const boostsData = require("../../plugins/boosts.json");

        commit("initializeCrops", crops);
        commit("initializeCropsData", cropsData);
        commit("initializeCropsDataRaw", cropsRawData);

        commit("initializeItems", items);
        commit("setItemsData", itemsData);
        commit("initializeItemsDataRaw", itemsRawData);

        commit("initializeBoostsData", boostsData);
    },

    getContractContent({ commit, rootState, state }, payload) {
        const farmContract = rootState.contracts.farm.farmContract;
    },
};

const getters = {};

const contractModule = {
    state: () => state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

export default contractModule;
