<template>
    <div class="base-tooltip">
        <div :style="getStyle" class="tooltip bounce-enter-active">
            <div class="tooltip-header"></div>

            <div class="content">
                <div class="content-info">
                    <CropDisplay
                        v-if="['seed', 'crop'].includes(tooltipMode)"
                        :displayType="tooltipMode"
                        :plantType="rawName"
                    ></CropDisplay>
                    <ItemDisplay
                        v-else-if="tooltipMode === 'item'"
                        :itemType="rawName"
                    >
                    </ItemDisplay>
                    <div class="content-info-name">
                        <p v-if="tooltipMode === 'item'" class="big-text">
                            {{ $t(`items.${name}`) }}
                        </p>
                        <p v-else class="big-text">
                            {{ $t(`crops.${name}`) }}
                        </p>
                        <p class="small-text" :class="`crops--${rarity}`">
                            {{ $t(`misc.rarity.${rarity}`) }}
                            <span v-if="tooltipMode === 'seed' && isPremium">
                                {{ $t("misc.premium") }}
                            </span>
                            <span v-if="tooltipMode === 'crop'">{{
                                $t("misc.crop")
                            }}</span>
                            <span v-if="tooltipMode === 'item'">{{
                                $t("misc.item")
                            }}</span>
                        </p>
                    </div>
                </div>

                <div class="content-info-description">
                    <p class="small-text" v-if="tooltipMode === 'item'">
                        {{ $t(`itemDescription.${itemKeyType}`) }}
                    </p>
                    <p v-else class="small-text">
                        {{ $t(`cropDescription.${rawName}`) }}
                    </p>
                </div>
                <div class="divider"></div>

                <template v-if="tooltipMode === 'seed'">
                    <div class="content-row">
                        <p class="small-text start">
                            {{ $t("modals.infoTooltip.totalHarvest") }}
                        </p>
                        <p class="small-text end">
                            {{
                                (
                                    (reward + reward * lockedRatio) *
                                    (1 + harvestBonus)
                                ).toFixed(2)
                            }}
                            $Yield
                        </p>
                    </div>

                    <div
                        class="content-row"
                        style="margin-left: 1%; width: 98%"
                    >
                        <p class="tiny-text start">
                            <span class="highlight-red">{{
                                $t("misc.locked")
                            }}</span>
                        </p>
                        <p class="tiny-text end">
                            <span class="highlight-red">
                                {{
                                    (
                                        lockedRatio *
                                        reward *
                                        (1 + harvestBonus)
                                    ).toFixed(2)
                                }}
                                $Yield</span
                            >
                        </p>
                    </div>
                    <div
                        class="content-row"
                        style="margin-left: 1%; width: 98%"
                    >
                        <p class="tiny-text start">
                            <span class="highlight">{{
                                $t("misc.unlocked")
                            }}</span>
                        </p>
                        <p class="tiny-text end">
                            <span class="highlight">
                                {{ (reward * (1 + harvestBonus)).toFixed(2) }}
                                $Yield</span
                            >
                        </p>
                    </div>

                    <br />
                    <div class="content-row">
                        <p class="small-text start">
                            {{ $t("modals.infoTooltip.growthTime") }}
                        </p>
                        <p class="small-text end">{{ getHarvestTime() }}</p>
                    </div>
                </template>

                <template v-if="tooltipMode === 'item'">
                    <div
                        v-for="(effectParagraph, index) in splitEffect"
                        class="content-info-description"
                        :key="'itemt' + index"
                    >
                        <p class="small-text">{{ effectParagraph }}.</p>
                    </div></template
                >
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import CropDisplay from "@/components/crops/CropDisplay.vue";
import GrowthStage from "@/components/crops/GrowthStage.vue";
import ItemDisplay from "@/components/crops/ItemDisplay.vue";

export default {
    name: "InfoTooltip",

    components: { CropDisplay, ItemDisplay, GrowthStage },
    props: {
        width: {
            type: Number,
            default: 400,
        },
        height: {
            type: Number,
            default: 310,
        },
    },

    data() {
        return {};
    },

    computed: {
        ...mapState("site/settings", ["mousePosition"]),
        ...mapState("site/tooltip", ["tooltipData", "tooltipMode"]),
        ...mapState("user", ["farmLevel", "lockedRatio"]),

        getStyle() {
            let { x, y } = this.mousePosition;
            const [windowWidth, windowHeight] = [
                window.innerWidth,
                window.innerHeight,
            ];

            if (x + this.width > windowWidth) {
                x = x - this.width - 64;
            }

            let finalHeight =
                this.tooltipMode === "seed" ? this.height : this.height - 96;

            if (this.tooltipMode === "item") {
                finalHeight = 310;
            }

            if (y + this.height > windowHeight) {
                y = y - finalHeight;
            }

            return {
                width: `${this.width}px`,
                height: `${finalHeight}px`,
                top: `${y}px`,
                left: `${x + 32}px`,
            };
        },

        name() {
            return this.tooltipData?.name || "Unknown";
        },

        rawName() {
            return this.tooltipData?.name || "Unknown";
        },

        itemKeyType() {
            return this.tooltipData?.tKey || "none";
        },

        rarity() {
            return this.tooltipData?.rarity || "common";
        },

        reward() {
            return this.tooltipData?.reward || 0;
        },

        isPremium() {
            return this.tooltipData?.tokenId > 12;
        },

        harvestBonus() {
            return this.farmLevel * 0.005;
        },

        description() {
            if (this.tooltipMode === "seed") {
                return (
                    this.tooltipData?.seedDescription ||
                    "No description available"
                );
            }
            return this.tooltipData?.description || "No description available";
        },

        splitEffect() {
            const { value, uses } = this.tooltipData || {};
            const data =
                this.$t(`itemEffects.${this.itemKeyType}`, {
                    v: value,
                    s: uses,
                }) || "";

            const strings = data.split(".") || [];
            return strings.filter((string) => string.length > 0);
        },
    },

    methods: {
        getHarvestTime() {
            const timeLeft = (this.tooltipData?.life || 0) * 1000;

            const hours = Math.floor(timeLeft / 3600000);
            const minutes = Math.floor(timeLeft / 60000) % 60;
            const seconds = ((timeLeft % 60000) / 1000).toFixed(0);

            if (hours > 0) return `${hours}h ${minutes}m`;
            if (minutes > 0) return `${minutes}m ${seconds}s`;
            return `${seconds}s`;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use "@/assets/styles/main.scss";
.base-tooltip {
    position: fixed;
    width: 100vw;
    height: 100vh;

    z-index: 10000;

    box-shadow: 4px 4px 4px #000000e8;
}

.divider {
    width: 90%;
    height: 0.1rem;
    background-color: #743f39;
    opacity: 0.5;
    margin: 0.5rem 0.5rem;
}
.tooltip {
    &-row {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 1rem;
    }
}
.tooltip {
    position: fixed;
    z-index: 10000;

    border-image: url("../../../public/images/modalBg.png") 6 fill repeat;
    /*   border-image: url(http://www.css3.info/wp-content/uploads/2007/09/border.png) 25 25 25 25 fill round; */
    border-image-width: 1rem;

    box-shadow: 4px 4px 32px #00000018;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &-header {
        min-height: 3rem;
        width: 12rem;
        max-width: 12rem;
        height: 3rem;
        z-index: 10001;

        position: absolute;
        top: -1.05rem;
        left: calc(50% - 6rem);

        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
    }
}

.content {
    margin-top: 1rem;

    height: calc(90% - 4rem);
    width: 90%;

    &-row {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        text-align: start !important;

        * {
            &.start {
                width: 50% !important;
                text-align: start;
            }

            &.end {
                width: 50% !important;
                text-align: end;
            }
        }
    }

    &-info {
        display: flex;
        gap: 1rem;

        text-align: start;

        &-name {
            margin-top: 0.5rem;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .big-text {
                font-size: 2.15rem !important;
            }
        }
    }

    &-info-description {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: center;

        text-align: start;

        overflow: hidden;
    }

    &-stage {
        width: 100%;
        margin-top: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.25rem;
    }
}

.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
        opacity: 0.75;
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
</style>
