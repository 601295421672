export async function getERC721FromReceipt(receipt, contract) {
    let data = [];
    for (let i = 0; i < receipt.logs.length; i++) {
        try {
            const decodedData = contract.interface.parseLog(receipt.logs[i]);
            if (decodedData.name === "Transfer") {
                data.push(parseInt(decodedData.args.tokenId));
            }
        } catch (err) {
            continue;
        }
    }
    return data;
}

export function getERC1155FromReceipt(receipt, contract) {
    for (let i = 0; i < receipt.logs.length; i++) {
        try {
            const decodedData = contract.interface.parseLog(receipt.logs[i]);
            if (decodedData.name === "TransferBatch") {
                const data = decodedData.args.toArray();
                const ids = data[3].map((id) => parseInt(id));
                const values = data[4].map((value) => parseInt(value));

                return {
                    ids: ids,
                    values: values,
                };
            }
        } catch (err) {
            continue;
        }
    }
}

export async function getLogData(receipt, contract, eventName) {
    let data = [];
    for (let i = 0; i < receipt.logs.length; i++) {
        try {
            const decodedData = contract.interface.parseLog(receipt.logs[i]);
            if (decodedData.name === eventName) {
                data.push(decodedData.args);
            }
        } catch (err) {
            continue;
        }
    }
    return data;
}

export async function getMulticallResults(fnName, data, target, iface) {
    const encodedCalls = [];

    if (data && data.length > 0) {
        if (data[0].length) {
            for (let i = 0; i < data.length; i++) {
                let fnData = iface.encodeFunctionData(fnName, data[i]);
                encodedCalls.push([target, fnData]);
            }
        } else {
            for (let i = 0; i < data.length; i++) {
                let fnData = iface.encodeFunctionData(fnName, [data[i]]);
                encodedCalls.push([target, fnData]);
            }
        }
    }
}

export default {
    getERC721FromReceipt,
    getERC1155FromReceipt,
    getLogData,
    getMulticallResults,
};
