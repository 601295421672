import { ethers } from "ethers";
import { createNotification } from "@/libs/storeHelper";

const state = () => ({
    itemControllerContract: null,

    consumerContracts: {},
});

const mutations = {
    initializeContract(state, contract) {
        state.itemControllerContract = contract;
    },

    setConsumerContracts(state, contracts) {
        state.consumerContracts = contracts;
    },
};

const actions = {
    async boot({ commit, dispatch, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/itemController.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(
            contractAddress,
            abi,
            signer || defaultSigner
        );

        commit("initializeContract", contract);

        await dispatch("getConsumerContracts");
    },

    async getConsumerContracts({ commit, rootState }) {
        const { relayerContract } = rootState.contracts.relayer;
        const { itemsDataRaw } = rootState.content;

        let contractNames = [
            ...new Set(itemsDataRaw.map((item) => item.contract)),
        ];

        const consumerContracts = await relayerContract.getMultipleContracts(
            contractNames
        );
        const consumerContractsData = {};

        for (let i = 0; i < consumerContracts.length; i++) {
            consumerContractsData[consumerContracts[i].name] =
                consumerContracts[i].addr;
        }

        commit("setConsumerContracts", consumerContractsData);
    },

    async useSelectedItem({ state, dispatch, rootState }, { selectedItem }) {
        const consumerContract = state.itemControllerContract;
        const { baseGwei } = rootState.site.settings;
        const { balances } = rootState.user;
        const { planterSpots } = rootState.planter;

        const indexes = [];

        for (let i = 0; i < planterSpots.length; i++) {
            if (!planterSpots[i] || planterSpots[i] === "") continue;
            indexes.push(i);
        }

        const { itemsData } = rootState.content;
        const itemUses = itemsData[selectedItem].uses;
        const consumableAddress =
            state.consumerContracts[itemsData[selectedItem].contract];
        const tokenId = itemsData[selectedItem].tokenId;

        let splitIndexes = [];
        let tx;

        let amount = 1;

        if (indexes.length > itemUses) {
            amount = Math.ceil(indexes.length / itemUses);

            for (let i = 0; i < indexes.length; i += itemUses) {
                splitIndexes.push(indexes.slice(i, i + itemUses));
            }

            tx = await consumerContract.useItems(
                tokenId,
                consumableAddress,
                splitIndexes,
                amount,
                {
                    gasPrice: baseGwei,
                }
            );
        } else {
            tx = await consumerContract.useItem(
                tokenId,
                consumableAddress,
                indexes,
                {
                    gasPrice: baseGwei,
                }
            );
        }

        if (balances.items[selectedItem].count < amount) {
            createNotification({
                dispatch,
                message: `You don't have enough ${selectedItem} to use`,
                type: "error",
                id: `not-enough-${selectedItem}`,
            });
            return;
        }

        dispatch("planter/resetPlanter", {}, { root: true });

        createNotification({
            dispatch,
            message: `Tx: Using ${amount} x ${selectedItem}`,
            type: "warn",
            id: `${tx.hash}`,
        });
        await tx.wait();

        dispatch("user/getStats", {}, { root: true });
        dispatch("site/notifications/remove", tx.hash, { root: true });

        createNotification({
            dispatch,
            message: `Used ${amount} x ${selectedItem}`,
            duration: 5000,
        });
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};
