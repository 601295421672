<template>
    <div class="growth-stage">
        <div class="growth-stage-connect">
            <div class="divider"></div>
        </div>

        <div class="growth-stage-wrapper">
            <div
                v-for="(s, sI) in maxStage"
                :key="'_s' + s"
                class="growth-stage-box"
                :class="[sI <= stage ? 'filled' : 'empty', 'green']"
            ></div>
        </div>

        <div class="effect-box" :class="[activeEffectClass]"></div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "BasicBox",

    components: {},

    props: {
        stage: {
            type: Number,
            default: 1,
        },
        boostModifier: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            maxStage: 5,
        };
    },

    methods: {},

    computed: {
        activeEffectClass() {
            if (this.boostModifier === 0) return "";
            if (this.boostModifier <= 3) return "red";
            return "green";
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.growth-stage {
    margin-top: 0.25rem;
    display: flex;
    gap: 0.25rem;
    position: relative;

    height: 0.25rem;

    &-connect {
        position: absolute;
        top: 0;
        left: 0;
    }

    &-wrapper {
        width: 100%;
        display: flex;
        gap: 0.05rem;
    }

    &-box {
        width: 0.125rem;
        border: 1px solid black;
        background-color: red;

        border-radius: 0.05rem;

        &.empty {
            background-color: #743f39;
            border: 1px solid #3f2832;
        }

        &.filled.green {
            background-color: #5ab552;
            border: 1px solid #743f39;
        }
    }
}

.effect-box {
    width: 0.125rem;
    height: 0.125rem;
    position: absolute;

    right: -0.5rem;
    &.red {
        background-color: #e64539;
        border: 1px solid #743f39;
    }

    &.green {
        background-color: #3b7d4f;
        border: 1px solid #743f39;
    }
}
</style>
