var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frame-container"},[_c('div',{ref:"game",staticClass:"game-frame"},[_c('div',{staticClass:"game-frame-farm"},[_c('div',[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("../../../public/images/island.png")}}),_c('div',{key:_vm.planterState},_vm._l((_vm.plantsRows),function(plantRow,rowIndex){return _c('div',{key:'pr' + plantRow},_vm._l((_vm.plantsPerRow),function(plantSpot,columnIndex){return _c('PlantSpot',{key:'pc' + plantSpot,attrs:{"position":_vm.getPositionForPlant(rowIndex, columnIndex),"crop":_vm.farmPlots[
                                    rowIndex * _vm.plantsPerRow + columnIndex
                                ]},on:{"clicked":function($event){return _vm.onPlantSlotClick(
                                    rowIndex * _vm.plantsPerRow + columnIndex
                                )}}})}),1)}),0),_c('Character',{attrs:{"hasBanner":_vm.planterState === 'inactive',"character":'farmer',"position":{ x: 470, y: 125 }},on:{"clicked":function($event){return _vm.setCurrentModal('planter')}}}),(_vm.hasTomato())?_c('div',[_c('img',{style:({
                            width: '33px',
                            position: 'absolute',
                            top: '318px',
                            left: '320px',
                        }),attrs:{"src":_vm.getTomatoUrl()}})]):_vm._e()],1)])]),(_vm.planterState === 'planting')?_c('div',{staticClass:"info-text"},[_c('p',{staticClass:"ghost-text"},[_vm._v(" ○ "+_vm._s(_vm.$t("misc.hold"))+" "),_c('span',{staticClass:"highlight"},[_vm._v("'Shift'")]),_vm._v(" "+_vm._s(_vm.$t("misc.keepPlanting"))+" ")]),(false)?_c('p',{staticClass:"ghost-text"},[_vm._v(" ○ Hold "),_c('span',{staticClass:"highlight"},[_vm._v("'Left Mouse'")]),_vm._v(" button down to plant the remaining seed balance. ")]):_vm._e()]):_vm._e(),(_vm.planterState === 'remover')?_c('div',{staticClass:"info-text"},[_c('p',{staticClass:"ghost-text"},[_vm._v("○ "+_vm._s(_vm.$t("farmScreen.removePlantNotice")))])]):_vm._e(),(_vm.planterState === 'useItem')?_c('div',{staticClass:"info-text"},[_c('p',{staticClass:"ghost-text"},[_vm._v("○ "+_vm._s(_vm.usedItemNotice))]),_c('p',{staticClass:"ghost-text"},[_c('span',{staticClass:"highlight"},[_vm._v("○ "+_vm._s(_vm.currentlyUsing))])])]):_vm._e(),(_vm.planterState !== 'inactive')?_c('div',{staticClass:"buttons"},[_c('div',{staticClass:"buttons-cancel"},[_c('BaseButton',{attrs:{"text":_vm.$t('misc.cancel')},on:{"clicked":_vm.resetPlanter}})],1),(_vm.planterState === 'planting')?_c('div',{staticClass:"buttons-confirm"},[_c('BaseButton',{attrs:{"text":_vm.$t('misc.plant')},on:{"clicked":_vm.tryPlantCulture}})],1):(_vm.planterState === 'harvesting')?_c('div',{staticClass:"buttons-confirm"},[_c('BaseButton',{attrs:{"text":_vm.$t('misc.harvest')},on:{"clicked":_vm.tryHarvestCulture}})],1):(_vm.planterState === 'remover')?_c('div',{staticClass:"buttons-confirm"},[_c('BaseButton',{attrs:{"text":_vm.$t('misc.remove')},on:{"clicked":_vm.tryRemoveCulture}})],1):_vm._e(),(_vm.planterState === 'useItem')?_c('div',{staticClass:"buttons-confirm"},[_c('BaseButton',{attrs:{"text":_vm.$t('misc.useItem')},on:{"clicked":_vm.tryUseItem}})],1):_vm._e()]):_vm._e(),(_vm.showTooltip)?_c('PlantInfoTooltip'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }