<template>
    <div id="app">
        <Transition name="fade">
            <div class="logo-loader" v-if="loading">
                <img width="512" src="../public/images/loader_logo.png" />
                <p class="big=text">Loading...</p>
            </div>
        </Transition>

        <div
            v-if="!loading"
            style="width: 100vw; height: 100vh; overflow: hidden"
        >
            <LoginPage v-if="isNewUser || isUnregisteredUser || !isOnBlast">
            </LoginPage>
            <router-view v-else></router-view>
        </div>

        <TransitionGroup name="list" class="app-notifications" tag="div">
            <BaseNotification
                v-for="(notification, index) in notifications"
                :key="notification.id"
                :notification="notification"
            ></BaseNotification>
        </TransitionGroup>

        <template v-if="!isNewUser && !isUnregisteredUser && isOnBlast">
            <SideMenu></SideMenu>

            <InfoTooltip v-if="showTooltip"></InfoTooltip>

            <div v-if="false" class="social-links">
                <a
                    >NOTICE: This is an exclusive early access demo on the BLAST
                    SEPOLIA TESTNET</a
                >
                <a href="https://twitter.com/CryptoValleys" target="_blank"
                    >Twitter</a
                >
                <a href="https://discord.com/invite/27cjx5tDsA" target="_blank"
                    >Discord</a
                >
            </div>
        </template>

        <component :is="currentModal" v-if="currentModal" @close="closeModal">
        </component>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import _ from "lodash";

import LoginPage from "@/views/LoginPage.vue";
import SideMenu from "@/components/sidebar/SideMenu.vue";
import InfoTooltip from "@/components/modal/InfoTooltip.vue";
import BaseNotification from "@/components/ui/BaseNotification.vue";

export default {
    name: "App",

    components: { LoginPage, BaseNotification, SideMenu, InfoTooltip },

    data() {
        return {};
    },

    async mounted() {},

    beforeDestroy() {
        this.clearUserRefreshInterval();
    },

    computed: {
        ...mapState("site", ["currentModal", "loading", "initialized"]),
        ...mapState("site/notifications", ["notifications"]),
        ...mapState("site/tooltip", ["showTooltip"]),
        ...mapGetters("user", ["isNewUser", "isUnregisteredUser", "isOnBlast"]),
    },

    methods: {
        ...mapActions("gacha", ["drawGacha"]),
        ...mapActions("site", [
            "setCurrentModal",
            "resetCurrentModal",
            "clearUserRefreshInterval",
            "boot",
        ]),
        ...mapActions("site/notifications", ["addNotification"]),
        ...mapActions("site/settings", ["playSound"]),
        ...mapActions("user", ["getStats"]),

        closeModal() {
            this.resetCurrentModal();
        },
    },
};
</script>

<style lang="scss">
@font-face {
    font-family: "Valley";
    src: url("../public/fonts/ByteBounce.ttf");
}

body {
    margin: 0;
}

.divider {
    width: 100%;
    height: 0.1rem;
    background-color: #743f39;
    opacity: 0.5;
    margin: 0.25rem 0;
}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
    position: absolute;
}

.fade-move, /* apply transition to moving elements */
.fade-enter-active,
.fade-leave-active {
    transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    overflow: hidden;

    font-family: "Valley", sans-serif;
    color: rgb(255, 243, 243);

    image-rendering: pixelated;

    user-select: none;

    width: 100vw;
    height: 100vh;
}

.beta-notice {
    padding: 0.1rem;
    background: #2b2d31;
    color: lightgrey;
    font-size: 1rem;
}

body {
    overflow: hidden;
    background: #2b2d31;
}

.app-notifications {
    position: fixed;
    top: 2rem;
    right: 2rem;
    z-index: 10001;
}

/* width */
::-webkit-scrollbar {
    width: 1rem;
}

/* Track */
::-webkit-scrollbar-track {
    background: #2c1c1a;
    border-radius: 0.25rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #bf6f4a;
    border-radius: 0.25rem;
    border: 2px solid #5d2c28;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #e69c69;
    border: 2px solid #302b28;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.logo-loader {
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
        font-size: 2.5rem;
    }

    gap: 3rem;

    animation: fade-in 0.2s ease-in-out;
    background-color: #2b2d31;

    position: fixed;
    z-index: 9999;
}

.ghost {
    position: absolute;
    opacity: 0.01;
    pointer-events: none;
    user-select: none;
}

.social-links {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 10;
}

.social-links a {
    display: inline-block;
    margin-left: 10px;
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    border-radius: 5px;
    text-decoration: none;
}

.account-temporary {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 10;
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    border-radius: 5px;
    text-decoration: none;
}

a {
    color: #ffd664;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: white;
    }
}
</style>
