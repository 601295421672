import { ethers } from "ethers";
import { createNotification } from "@/libs/storeHelper";

const state = () => ({
    sageContract: null,

    timeTillNextEpoch: 0,
    unlockRate: 0.015,
});

const mutations = {
    initializeContract(state, contract) {
        state.sageContract = contract;
    },

    setTimeTillNextEpoch(state, time) {
        state.timeTillNextEpoch = Number(time) * 1000;
    },
};

const actions = {
    boot({ commit, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/sage.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(
            contractAddress,
            abi,
            signer || defaultSigner
        );

        commit("initializeContract", contract);

        contract.timeTillNextEpoch().then((time) => {
            commit("setTimeTillNextEpoch", Number(time));
        });
    },

    async claimHarvest({ state, dispatch, rootState }) {
        const contract = state.sageContract;
        const { baseGwei } = rootState.site.settings;

        const tx = await contract.claim({
            gasPrice: baseGwei,
        });

        createNotification({
            dispatch,
            message: `Tx: Claiming harvest!`,
            type: "warn",
            id: tx.hash,
        });

        await tx.wait(1);

        dispatch("user/getStats", {}, { root: true });
        dispatch("site/notifications/remove", tx.hash, { root: true });

        createNotification({
            dispatch,
            message: `Harvest claimed!`,
            duration: 5000,
        });
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};
