import { ethers } from "ethers";
import { createNotification } from "@/libs/storeHelper";
import fromExponential from "from-exponential";

const state = () => ({
    kickstarterContract: null,

    totalTokensPurchased: 0,
    tokensLeft: 0,
    totalUsers: 0,
    totalUsersClaimed: 0,
    isPaused: false,
    claimable: false,
    maxPerWallet: 100_000,

    pricePerToken: 0,
    tokenCap: 0,
    timeLeft: 0,

    transactions: [],
    // constnats

    TOKEN_CAP: 0,
    PRICE_PER_TOKEN: 0,

    refreshInterval: undefined,

    launchTimestamp: 1710187208119 - new Date().getTime(),
});

const mutations = {
    initializeContract(state, contract) {
        state.kickstarterContract = contract;
    },

    setContractData(state, contractData) {
        state.totalTokensPurchased =
            Number(contractData.totalTokensPurchased) / 1e18;
        state.tokensLeft = Number(contractData.tokensLeft) / 1e18;
        state.totalUsers = Number(contractData.totalUsers);

        state.totalUsersClaimed = Number(contractData.totalUsersClaimed);
        state.isPaused = contractData.isPaused;
        state.claimable = contractData.claimable;

        state.TOKEN_CAP = contractData.TOKEN_CAP;
        state.tokenCap = Number(contractData.TOKEN_CAP) / 1e18;
        state.PRICE_PER_TOKEN = contractData.PRICE_PER_TOKEN;
        state.pricePerToken = fromExponential(
            Number(contractData.PRICE_PER_TOKEN) / 1e18
        );
    },

    setTransactionHistory(state, transactions) {
        const parsedTransactions = [];
        for (let i = 0; i < transactions.length; i++) {
            const tx = transactions[i];
            const address = tx.user.slice(0, 6) + "..." + tx.user.slice(-4);
            const amount = (Number(tx.amount) / 1e18).toFixed(4);
            parsedTransactions.push({ address, amount });
        }

        state.transactions = parsedTransactions;
    },

    setRefreshInterval(state, interval) {
        clearInterval(state.refreshInterval);
        state.refreshInterval = interval;
    },
};

const actions = {
    boot({ commit, rootState, dispatch }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/kickstarter.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(
            contractAddress,
            abi,
            signer || defaultSigner
        );

        commit("initializeContract", contract);
        dispatch("getContractData", null, { root: false });

        const interval = setInterval(() => {
            dispatch("getContractData", null, { root: false });
            dispatch("getTransactionHistory");
        }, 10000);
        commit("setRefreshInterval", interval);
    },

    async getContractData({ commit, state }) {
        const contract = state.kickstarterContract;
        const contractData = await contract.getStats();

        commit("setContractData", contractData);
    },

    async getContractUserData({ state, rootState }) {
        const { storedAccount } = rootState.user;
        const contract = state.kickstarterContract;

        const userData = await contract.getUserData(storedAccount);

        return userData;
    },

    async getTransactionHistory({ state, commit }) {
        const contract = state.kickstarterContract;
        const transactions = await contract.getLastTransactions(25);
        commit("setTransactionHistory", transactions);
    },

    async swapTokens({ state, dispatch, rootState }, { amount, ethValue }) {
        const contract = state.kickstarterContract;
        const { baseGwei } = rootState.site.settings;

        console.log(`Exchanging ${ethValue} ETH for ${amount} tokens`);

        if (ethValue <= 0 || !ethValue || !amount || amount <= 0) {
            createNotification({
                dispatch,
                type: "error",
                message: "Invalid Amount",
                id: `invalid-amount`,
                duration: 5000,
            });
            return;
        }

        const tx = await contract.fund(amount, {
            value: ethValue,
            gasPrice: baseGwei,
        });

        createNotification({
            dispatch,
            message: `Tx: Purchasing Yield!`,
            type: "warn",
            id: tx.hash,
        });

        await tx.wait(1);

        dispatch("getContractData");
        dispatch("site/notifications/remove", tx.hash, { root: true });
    },

    async claimTokens({ state, dispatch, rootState }) {
        const contract = state.kickstarterContract;
        const { baseGwei } = rootState.site.settings;

        const tx = await contract.claim({
            gasPrice: baseGwei,
        });

        dispatch("site/notifications/create", tx.hash, { root: true });

        await tx.wait(1);

        dispatch("getContractData");
        dispatch("site/notifications/remove", tx.hash, { root: true });
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};
