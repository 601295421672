<template>
    <div class="label">
        <p class="small-text">
            {{ text }}
        </p>
        <div
            class="small-text"
            style="display: flex; align-items: center; justify-content: center"
        >
            <span class="small-text-cut" v-if="prevValue">{{ prevValue }}</span>
            <span class="small-text-arrow" v-if="prevValue">►</span>
            <span class="small-text-highlight">{{ value }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseButton",

    components: {},

    props: {
        text: {
            type: String,
            default: "",
        },
        prevValue: {
            type: String,
            default: "",
        },
        value: {
            type: String,
            default: "",
        },
    },

    data() {
        return {};
    },

    methods: {},

    computed: {
        getLabelIcon() {
            return require(`@/assets/icons/${this.icon}`);
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.label {
    width: 100%;
    height: 100%;
    min-height: 2rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    transition: all 0.1s ease-in;
    cursor: pointer;

    user-select: none;

    border-bottom: 2px solid rgba(0, 0, 0, 0.25);
}

.small-text {
    &-cut {
        opacity: 0.8;
        text-decoration: line-through !important;
    }

    &-arrow {
        color: #5ab552;
        margin: 0 0.5rem;
        font-size: 1rem;
    }

    &-highlight {
        color: #ffc23e;
    }
}
</style>
