var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"game",staticClass:"game-frame"},[_c('div',{staticClass:"game-frame-overworld"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require("../../../public/images/overworld.png")}}),_c('MapBanner',{attrs:{"position":{
                x: 1410,
                y: 380,
            },"size":'big',"text":_vm.$t('menu.farm')},on:{"clicked":function($event){return _vm.goToRoute('farm')}}}),_c('MapBanner',{attrs:{"position":{
                x: 450,
                y: 280,
            },"size":'big',"text":_vm.$t('menu.house')},on:{"clicked":function($event){return _vm.goToRoute('house')}}}),_c('MapBanner',{attrs:{"position":{
                x: 790,
                y: 560,
            },"text":_vm.$t('menu.marketplace'),"size":'big'},on:{"clicked":function($event){return _vm.goToRoute('marketplace')}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }