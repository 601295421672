<template>
    <div class="game-frame" ref="game">
        <div class="game-frame-marketplace">
            <img
                style="width: 100%; height: 100%"
                src="../../../public/images/marketplace.png"
            />

            <Character
                v-if="false"
                @clicked="setCurrentModal('seedShop')"
                :character="'vendor'"
                :position="{ x: 832, y: 270 }"
            ></Character>

            <Character
                v-if="false"
                @clicked="setCurrentModal('sage')"
                :character="'sage'"
                :position="{ x: 577, y: 76 }"
            ></Character>

            <Character
                @clicked="setCurrentModal('kickstarter')"
                :character="'dex'"
                :position="{ x: 464, y: 220 }"
            ></Character>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "MarketplaceScreen",

    components: {},

    props: {},

    data() {
        return {};
    },

    mounted() {
        const panzoomContainer = this.$refs.game;
        this.initializePanzoom(panzoomContainer);
        this.resetZoom();
    },

    beforeDestroy() {
        this.destroyPanzoom();
        this.resetZoom();
    },

    methods: {
        ...mapActions("site", ["setCurrentModal"]),
        ...mapActions("panzoom", [
            "initializePanzoom",
            "destroyPanzoom",
            "zoomTo",
            "resetZoom",
        ]),
    },

    computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.game-frame {
    min-width: 960px;
    min-height: 480px;
    width: 100%;
    height: 100%;

    &-marketplace {
        width: 960px;
        height: 480px;
        border-radius: 0.25rem;

        border-image: url("../../../public/images/buttonBg.png") 6 fill repeat;
        border-image-width: 1rem;
        border-image-outset: 0.5rem;

        position: absolute;
        top: calc(50% - 240px);
        left: calc(50% - 480px);
    }
}
</style>
