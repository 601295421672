<template>
    <div :key="crop.stage" :style="plantPosition">
        <div
            :style="plantStyle"
            :class="[
                selected ? 'selected' : '',
                focused ? 'plant-spot-active' : '',
                over && !locked ? 'hover' : '',
                empty ? '' : 'planted',
            ]"
            class="plant-spot premium"
        ></div>
        <div v-if="crop.cropId && showPlantTooltip" class="plant-spot-tooltip">
            <GrowthDisplay
                :boost-modifier="crop.boostModifier || 0"
                :stage="crop.stage"
            ></GrowthDisplay>
        </div>
        <div
            @click="handleClick"
            @mouseover="setHover"
            @mouseleave="unsetHover"
            class="bounding-box"
        ></div>
    </div>
</template>

<script>
import GrowthDisplay from "@/components/crops/GrowthDisplay.vue";
import { mapActions, mapState } from "vuex";

export default {
    name: "BaseButton",

    components: { GrowthDisplay },

    props: {
        crop: {
            type: Object,
            default: () => {},
        },
        position: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            over: false,
        };
    },

    methods: {
        ...mapActions("planter", ["setPlantHovered", "resetPlantHovered"]),
        ...mapActions("site", ["setCurrentModal"]),
        ...mapActions("site/settings", ["playSound"]),

        setHover() {
            this.playSound({ track: "bush.webm", volume: 0.1 });
            this.setPlantHovered(this.crop);
            this.over = true;
        },

        unsetHover() {
            this.resetPlantHovered();
            this.over = false;
        },

        handleClick() {
            if (this.locked) return;

            if (this.empty && this.planterState !== "planting") {
                this.playSound({ track: "click.wav", volume: 0.1 });
                if (this.empty && this.planterState === "inactive") {
                    this.setCurrentModal("planter");
                }
                return;
            }

            if (!this.empty && this.planterState === "inactive") {
                this.playSound({ track: "click.wav", volume: 0.1 });
                this.setCurrentModal("planter");
                return;
            }

            if (this.planterState === "harvesting") {
                if (this.crop.stage < 4 || this.empty) {
                    this.$store.dispatch("site/notifications/create", {
                        type: "error",
                        message: "Can't harvest this crop yet!",
                        id: Date.now(),
                        duration: 5000,
                    });
                    return;
                }
            }

            if (this.planterState === "planting") {
                if (!this.empty) {
                    this.$store.dispatch("site/notifications/create", {
                        type: "error",
                        message: "This spot is already planted!",
                        id: Date.now(),
                        duration: 5000,
                    });
                    return;
                }
            }

            if (this.planterState === "remover") {
                if (this.empty) {
                    this.$store.dispatch("site/notifications/create", {
                        type: "error",
                        message: "This spot is already empty!",
                        id: Date.now(),
                        duration: 5000,
                    });
                    return;
                }
            }

            this.$emit("clicked");
        },
    },

    computed: {
        ...mapState("content", ["cropsData"]),
        ...mapState("planter", ["planterState", "planterSpots"]),
        ...mapState("site/settings", ["showPlantTooltip"]),

        plantPosition() {
            return {
                width: "4rem",
                height: "4rem",
                position: "absolute",
                left: `${this.position?.x || 0}px`,
                top: `${this.position?.y || 0}px`,
            };
        },

        focused() {
            if (this.planterState === "planting") {
                if (!this.locked && this.empty) return true;
            }
            if (this.planterState === "harvesting") {
                if (!this.locked && !this.empty && this.crop?.stage >= 4)
                    return true;
            } else if (this.planterState === "remover") {
                if (!this.locked && !this.empty) return true;
            }

            return false;
        },

        locked() {
            return this.crop?.locked || false;
        },

        empty() {
            return this.crop?.cropId === 0;
        },

        selected() {
            return (
                this.planterState !== "inactive" &&
                !this.locked &&
                this.planterSpots[this.crop.index] !== ""
            );
        },

        plantStyle() {
            const locked = this.crop?.locked || false;

            if (locked) {
                const plantStyle = {
                    backgroundPositionY: `-0px`,
                    backgroundPositionX: `-64px`,
                };

                return { ...plantStyle };
            }

            const tokenId = this.crop?.cropId || 0;
            const isEmpty = this.empty;

            if (this.planterState === "planting" && isEmpty) {
                const cropType = this.planterSpots[this.crop.index] || "empty";
                const cropId = this.cropsData[cropType]?.tokenId || 0;
                const plantStyle = {
                    backgroundPositionY: `-${cropId * 64}px`,
                    backgroundPositionX:
                        cropType === "empty" ? "-0px" : `-64px`,
                };

                return { ...plantStyle };
            } else {
                const stage = locked ? 2 : this.crop?.stage;

                const plantStyle = {
                    backgroundPositionY: `-${64 * tokenId}px`,
                    backgroundPositionX: `-${(stage + 2) * 64}px`,
                };

                return { ...plantStyle };
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bounding-box {
    position: absolute;
    top: 33%;
    right: 1rem;
    left: 1rem;
    bottom: -10%;
    z-index: 1000;
    cursor: pointer;
}
.plant-spot {
    image-rendering: pixelated;
    width: 4rem;
    height: 4rem;
    background: brown;
    transform-origin: 32px 64px;

    background: url("../../../public/images/bigger_plants.png");
    cursor: pointer;
    pointer-events: none;

    &-tooltip {
        width: 100%;
        height: 1rem;
        display: flex;
        justify-content: center;
        align-self: center;
    }

    &.hover {
        filter: brightness(1.2);
    }

    &-active {
        animation: jiggle 0.5s ease-in-out infinite;
    }

    &.selected {
        -webkit-filter: drop-shadow(1px 1px 0 white)
            drop-shadow(-1px 1px 0 white) drop-shadow(1px -1px 0 white)
            drop-shadow(-1px -1px 0 white);

        filter: drop-shadow(1px 1px 0 white) drop-shadow(-1px 1px 0 white)
            drop-shadow(1px -1px 0 white) drop-shadow(-1px -1px 0 white);
    }
}

.planted {
    animation: planted 0.25s ease-in-out;
}

.harvested {
    animation: harvested 0.25s ease-in-out;
}

@keyframes jiggle {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes planted {
    0% {
        transform: scaleX(1.15);
    }
    100% {
        transform: scaleX(1);
    }
}

@keyframes harvested {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-16px);
    }
}

@keyframes MoveBackgroundPosition {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}
</style>
