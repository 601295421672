<template>
    <div
        @click="handleClick"
        :style="mapPosition"
        class="map-banner"
        :class="[size]"
    >
        <p class="tiny-text">
            {{ parsedText }}
        </p>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "MapBanner",

    components: {},

    props: {
        lnKey: {
            type: String,
            default: "",
        },
        text: {
            type: String,
            default: "",
        },
        position: {
            type: Object,
            default: () => {},
        },
        size: {
            type: String,
            default: "small",
        },
    },

    data() {
        return {};
    },

    methods: {
        ...mapActions("site/settings", ["playSound"]),

        handleClick() {
            this.$emit("clicked");
            this.playSound({
                track: "click.wav",
                volume: 0.1,
            });
        },
    },

    computed: {
        mapPosition() {
            const bannerPosition = {
                position: "absolute",
                left: `${this.position?.x || 0}px`,
                top: `${this.position?.y || 0}px`,
            };

            return { ...bannerPosition };
        },

        parsedText() {
            if (this.lnKey) {
                return this.$t(this.lnKey);
            }

            return (this.text || "").toUpperCase();
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.map-banner {
    min-width: 10rem;
    height: 3rem;

    animation: float 5s ease-in-out infinite;
    background: url("../../../public/images/mapBanner.png");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;

    p {
        width: auto;
        font-size: 1.25rem !important;
        letter-spacing: 0.05rem !important;
        margin-bottom: 0.8rem;
    }

    &.big {
        min-width: 20rem;
        height: 4.5rem;

        p {
            width: auto;
            font-size: 1.7rem !important;
            letter-spacing: 0.05rem !important;
            margin-bottom: 1.1rem;
        }
    }

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    background-clip: border-box;
    transition: all 0.25s ease-in-out;

    transform: translateZ(1px);

    &:hover {
        animation: none;
        transform: scale(1.1) translateZ(1px);
    }
}

@keyframes float {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0px);
    }
}
</style>
