const state = () => ({
    pressedKeys: {},
    mousePosition: { x: 0, y: 0 },
    interacted: false,

    soundVolume: localStorage.getItem("cv:soundVolume")
        ? Number(localStorage.getItem("cv:soundVolume"))
        : 50,
    musicVolume: localStorage.getItem("cv:musicVolume")
        ? Number(localStorage.getItem("cv:musicVolume"))
        : 25,
    soundMuted: localStorage.getItem("cv:soundMuted") === "true" || false,
    dexSlippage: localStorage.getItem("cv:dexSlippage")
        ? parseFloat(localStorage.getItem("cv:dexSlippage"))
        : 0.005,
    baseGwei:
        localStorage.getItem("cv:baseGwei") ||
        Number(localStorage.getItem("cv:baseGwei")) ||
        0.5e9,

    showPlantTooltip:
        localStorage.getItem("cv:showPlantTooltip") === "true" || false,

    soundTrackTimers: {
        gacha: 0,
        click: 0,
        plant: 0,
        harvest: 0,
    },

    soundTrack: {
        gacha: null,
        click: null,
        plant: null,
        harvest: null,
    },

    soundLoop: null,
});

const mutations = {
    setPressedKeys(state, pressedKeys) {
        state.pressedKeys = pressedKeys;
    },

    togglePressedKey(state, payload) {
        const { key, value } = payload;
        state.pressedKeys[key] = value;
    },

    setMousePosition(state, payload) {
        const { x, y } = payload;
        state.mousePosition = { x, y };
    },

    setSoundVolume(state, volume) {
        state.soundVolume = volume;
        localStorage.setItem("cv:soundVolume", volume);
    },

    setMusicVolume(state, volume) {
        state.musicVolume = volume;
        localStorage.setItem("cv:musicVolume", volume);

        Object.keys(state.soundTrack).forEach((track) => {
            if (state.soundTrack[track]) {
                state.soundTrack[track].volume = Math.min(
                    1,
                    state.musicVolume / 100
                );
            }
        });
    },

    setSoundMuted(state, muted) {
        state.soundMuted = muted;
        localStorage.setItem("cv:soundMuted", muted);
    },

    setShowPlantTooltip(state, show) {
        state.showPlantTooltip = show;
        localStorage.setItem("cv:showPlantTooltip", show);
    },

    setDexSlippage(state, slippage) {
        state.dexSlippage = slippage;
        localStorage.setItem("cv:dexSlippage", slippage);
    },

    setBaseGwei(state, gwei) {
        state.baseGwei = gwei;
        localStorage.setItem("cv:baseGwei", gwei);
    },

    setInteracted(state, interacted) {
        state.interacted = interacted;
    },

    registerSoundTrack(state, payload) {
        const { audio, track } = payload;
        state.soundTrackTimers[track] = 66;
        state.soundTrack[track] = audio;
    },
};

const actions = {
    async boot({ commit, dispatch, state, rootState }) {
        window.onkeyup = (e) => {
            commit("togglePressedKey", { key: e.key, value: false });
        };

        window.onkeydown = (e) => {
            commit("togglePressedKey", { key: e.key, value: true });
        };

        window.onmousemove = (e) => {
            commit("setMousePosition", { x: e.clientX, y: e.clientY });
        };

        window.onclick = (e) => {
            if (!state.interacted) {
                commit("setInteracted", true);

                dispatch("playMusic", {
                    track: "song.webm",
                    loop: true,
                    volume: 0.025,
                });

                dispatch("playMusic", {
                    track: "birds.webm",
                    loop: false,
                    volume: 0.025,
                });
            }
        };

        state.soundLoop = setInterval(() => {
            Object.keys(state.soundTrackTimers).forEach((track) => {
                if (state.soundTrackTimers[track] > 0) {
                    state.soundTrackTimers[track] -= 33;

                    // Clear sound track if it's done playing
                    if (state.soundTrackTimers[track] <= 0) {
                        state.soundTrackTimers[track] = 0;
                    }
                }
            });
        }, 33);
    },

    playSound({ commit, state }, payload) {
        const { track, loop } = payload;

        let finalVolume = state.soundVolume / 100;
        if (!state.interacted) return;
        if (state.soundMuted) {
            finalVolume = 0;
        }
        if (state.soundTrackTimers[track] > 0) return;

        const audioAsset = require(`@/assets/sounds/${track}`);
        const audio = new Audio(audioAsset);
        audio.loop = loop || false;
        audio.volume = finalVolume;
        commit("registerSoundTrack", { audio, track });
        audio.play();
    },

    playMusic({ commit, state }, payload) {
        const { track, loop } = payload;

        let finalVolume = state.musicVolume / 100;
        if (!state.interacted) return;
        if (state.soundMuted) {
            finalVolume = 0;
        }

        const audioAsset = require(`@/assets/sounds/${track}`);
        const audio = new Audio(audioAsset);
        audio.loop = loop || false;
        audio.volume = finalVolume;
        commit("registerSoundTrack", { audio, track });
        audio.play();
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};
