export function createNotification({
    dispatch,
    message,
    type,
    id,
    duration = 30000,
}) {
    dispatch(
        "site/notifications/create",
        {
            type: type || "success",
            message,
            duration,
            id: id || `${Date.now()}-${Math.random()}`,
        },
        { root: true }
    );
}

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
