import Vue from "vue";

import settingsModule from "@/store/site/settings";
import notificationsModule from "@/store/site/notifications";
import tooltipModule from "@/store/site/tooltip";

import { ethers } from "ethers";
import { ZERO_ADDRESS } from "@/libs/storeHelper";
import _ from "lodash";

import i18n, { selectedLocale } from "@/lang/i18n";
import { ensureLocaleLoaded } from "@/libs/i18n-helpers";

import { countryLocales } from "@/libs/country";
/**
 * @typedef {typeof state} State
 */
const state = {
    initialized: false,
    loading: false,

    signer: undefined,
    defaultSigner: undefined,

    locale: selectedLocale,

    modals: {
        planter: false,
        gardener: false,
        seedPicker: false,
        referral: false,
        seedShop: false,
        gachaResult: false,
        nameModal: false,
        faucet: false,
        inventory: false,
        settings: false,
        sage: false,
        avatarPicker: false,
        dexInterface: false,
        kickstarter: false,
        wallet: false,
    },

    modalsComponents: {},
    currentModal: null,

    userDataRefreshInterval: null,
};

/**
 * @type {import("vuex").MutationTree<State>}
 */
const mutations = {
    setInitialized(state, initialized) {
        state.initialized = initialized;
    },

    setLoading(state, loading) {
        Vue.set(state, "loading", loading);
    },

    setLocale(state, locale) {
        state.locale = locale;
    },

    setModalsComponents(state, components) {
        state.modalsComponents = components;
    },

    setDefaultSigner(state, signer) {
        state.defaultSigner = signer;
    },

    setSigner(state, signer) {
        state.signer = signer;
    },

    setCurrentModal(state, modalName) {
        state.currentModal = state.modalsComponents[modalName];
    },

    setUserRefreshInterval(state, interval) {
        state.userDataRefreshInterval = interval;
    },

    clearUserRefreshInterval(state) {
        clearInterval(state.userDataRefreshInterval);
    },
};

/**
 * @type {import("vuex").ActionTree<State, import("@/store/index").RootState>}
 */
const actions = {
    async boot({ commit, rootState, dispatch, state }) {
        try {
            await dispatch("content/boot", {}, { root: true });
            dispatch("site/tooltip/boot", {}, { root: true });
            dispatch("site/settings/boot", {}, { root: true });
            await dispatch("changeLocale", { lang: state.locale });

            const modals = Object.keys(state.modals);
            const modalsComponents = {};
            modals.forEach((modal) => {
                modalsComponents[modal] = () =>
                    import(
                        `@/components/modal/${_.upperFirst(modal)}Modal.vue`
                    );
            });
            commit("setModalsComponents", modalsComponents);

            const defaultSigner = new ethers.JsonRpcProvider(
                process.env.VUE_APP_DEV_RPC
            );
            commit("setDefaultSigner", defaultSigner);

            await dispatch("contracts/boot", {}, { root: true });
            await dispatch("user/getStats", {}, { root: true });

            const interval = setInterval(() => {
                dispatch("user/getStats", {}, { root: true });
            }, 10000);

            commit("setInitialized", true);
            commit("setUserRefreshInterval", interval);

            setTimeout(async () => {
                window.ethereum.on("accountsChanged", async (accounts) => {
                    if (accounts.length === 0) {
                        dispatch("disconnectWallet");
                    } else {
                        commit("setLoading", true);
                        const account = accounts[0];
                        localStorage.setItem("cv:stored_account", account);
                        await dispatch("connectWallet", { forceSigner: true });
                        commit("setLoading", false);
                    }
                });

                window.ethereum.on("chainChanged", async (chainId) => {
                    commit("user/setChainId", Number(chainId), { root: true });
                });

                const chainId = await window.ethereum.request({
                    method: "eth_chainId",
                });
                commit("user/setChainId", Number(chainId), { root: true });
            }, 100);
        } catch (error) {
            console.log(error);
        }
    },

    async connectWallet(
        { commit, dispatch, rootState, state },
        { forceSigner = false }
    ) {
        if (!window.ethereum) return;

        try {
            const storedAccount =
                localStorage.getItem("cv:stored_account") || ZERO_ADDRESS;

            const provider = new ethers.BrowserProvider(window.ethereum);

            if (
                !storedAccount ||
                storedAccount === ZERO_ADDRESS ||
                forceSigner
            ) {
                const ethAccounts = await window.ethereum.request({
                    method: "eth_accounts",
                });
                const account = ethAccounts[0];

                //
                if (account) {
                    commit("user/setAccount", account, { root: true });
                    const signer = new ethers.JsonRpcSigner(provider, account);
                    commit("setSigner", signer);
                    console.log("Reload contracts");
                    await dispatch("contracts/boot", {}, { root: true });
                    return;
                }

                const signer = await provider.getSigner();
                const network = await provider.getNetwork();
                const chainId = parseInt(network.chainId);
                const accounts = await provider.listAccounts();

                commit("user/setAccount", accounts[0].address, { root: true });
                commit("user/setChainId", chainId, { root: true });
                commit("setSigner", signer);
                // reload contracts
                await dispatch("contracts/boot", {}, { root: true });
                await dispatch("user/getStats", {}, { root: true });
                return;
            }

            commit("user/setAccount", storedAccount, { root: true });
            const signer = new ethers.JsonRpcSigner(provider, storedAccount);
            commit("setSigner", signer);
        } catch (err) {
            console.log(err);
        }
    },

    disconnectWallet({ commit, dispatch, state }) {
        commit("user/setAccount", ZERO_ADDRESS, { root: true });
        commit("setSigner", undefined);
        localStorage.removeItem("cv:stored_account");
        dispatch("user/getStats", {}, { root: true });
        dispatch("resetCurrentModal");
    },

    async requestChangeNetwork({ commit, dispatch, state }) {
        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: "0x13e31" }],
            });
        } catch (error) {
            if (error.code === 4902) {
                await dispatch("addChain");
            }
        }
    },

    async addChain({ commit, dispatch, state }, payload) {
        const blastMainnet = {
            chainId: "0x13e31",
            chainName: "Blast Mainnet",
            nativeCurrency: {
                name: "Ethereum",
                symbol: "ETH",
                decimals: 18,
            },
            blockExplorerUrls: ["https://rpc.blast.io/"],
            rpcUrls: ["https://rpc.blast.io/"],
        };
        const blastSepolia = {
            chainId: "0xA0C71FD",
            chainName: "Blast Sepolia",
            nativeCurrency: {
                name: "Blast Sepolia",
                symbol: "ETH",
                decimals: 18,
            },
            blockExplorerUrls: ["https://testnet.blastscan.io/"],
            rpcUrls: ["https://sepolia.blast.io/"],
        };

        await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [blastMainnet],
        });
    },

    setCurrentModal({ commit, dispatch, state }, modalName) {
        commit("setCurrentModal", modalName);
    },

    resetCurrentModal({ commit, dispatch, state }) {
        commit("setCurrentModal", null);
    },

    clearUserRefreshInterval({ commit, dispatch, state }) {
        commit("clearUserRefreshInterval");
    },

    setLoading({ commit, dispatch, state }, payload) {
        commit("setLoading", payload);
    },

    async changeLocale({ commit }, { lang }) {
        const currentLocale = localStorage.getItem("site:locale");
        if (!currentLocale) {
            const languages = navigator.languages;
            for (const l in languages) {
                let language = languages[l];
                if (countryLocales.hasOwnProperty(language)) {
                    lang = language;
                    break;
                }
            }
        }
        document.querySelector("html").classList.add("en");
        /*await ensureLocaleLoaded(lang);

        i18n.locale = lang;
        document.querySelector("html").setAttribute("lang", lang);
        document.querySelector("html").classList.add(lang);
        localStorage.setItem("site:locale", lang);

        commit("setLocale", lang);*/
    },
};

/**
 * @type {import("vuex").GetterTree<State, import("@/store/index").RootState>}
 */
const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
    modules: {
        settings: settingsModule,
        notifications: notificationsModule,
        tooltip: tooltipModule,
    },
};
