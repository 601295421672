<template>
    <div>
        <MapBanner
            v-if="hasBanner"
            @clicked="handleClick"
            :text="characterText"
            :position="{ x: position.x - 64, y: position.y - 60 }"
        ></MapBanner>
        <div
            @click="handleClick"
            :style="characterStyle"
            class="map-character"
        ></div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "Character",

    components: {},

    props: {
        character: {
            type: String,
            default: "farmer",
        },
        position: {
            type: Object,
            default: () => {},
        },
        hasBanner: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {};
    },

    methods: {
        ...mapActions("site/settings", ["playSound"]),

        handleClick() {
            this.$emit("clicked");
            this.playSound({
                track: "click.wav",volume: 0.1
            });
        },
    },

    computed: {
        characterStyle() {
            const character = require(`../../assets/characters/${this.character}.png`);
            return {
                position: "absolute",
                left: `${this.position?.x || 0}px`,
                top: `${this.position?.y || 0}px`,
                background: `url('${character}') 0 0`,
            };
        },

        characterText() {
            const text = this.$t(`npcs.${this.character}`);
            return text;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.map-character {
    transform: scale(2);
    width: 2rem;
    height: 2rem;
    animation: float 3s steps(1, end) infinite;
    cursor: pointer;
}

@keyframes float {
    0% {
        background-position-x: 0;
    }
    50% {
        background-position-x: -32px;
    }
    75% {
        background-position-x: 0;
    }
    100% {
        background-position-x: 0;
    }
}
</style>
