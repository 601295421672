<template>
    <div class="frame-container">
        <div class="game-frame" ref="game">
            <div class="game-frame-farm">
                <div>
                    <img
                        style="width: 100%; height: 100%"
                        src="../../../public/images/island.png"
                    />

                    <div :key="planterState">
                        <div
                            v-for="(plantRow, rowIndex) in plantsRows"
                            :key="'pr' + plantRow"
                        >
                            <PlantSpot
                                @clicked="
                                    onPlantSlotClick(
                                        rowIndex * plantsPerRow + columnIndex
                                    )
                                "
                                :position="
                                    getPositionForPlant(rowIndex, columnIndex)
                                "
                                :crop="
                                    farmPlots[
                                        rowIndex * plantsPerRow + columnIndex
                                    ]
                                "
                                v-for="(plantSpot, columnIndex) in plantsPerRow"
                                :key="'pc' + plantSpot"
                            >
                            </PlantSpot>
                        </div>
                    </div>

                    <Character
                        @clicked="setCurrentModal('planter')"
                        :hasBanner="planterState === 'inactive'"
                        :character="'farmer'"
                        :position="{ x: 470, y: 125 }"
                    ></Character>

                    <div v-if="hasTomato()">
                        <img
                            :src="getTomatoUrl()"
                            :style="{
                                width: '33px',
                                position: 'absolute',
                                top: '318px',
                                left: '320px',
                            }"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div v-if="planterState === 'planting'" class="info-text">
            <p class="ghost-text">
                ○ {{ $t("misc.hold") }} <span class="highlight">'Shift'</span>
                {{ $t("misc.keepPlanting") }}
            </p>
            <p v-if="false" class="ghost-text">
                ○ Hold <span class="highlight">'Left Mouse'</span> button down
                to plant the remaining seed balance.
            </p>
        </div>

        <div v-if="planterState === 'remover'" class="info-text">
            <p class="ghost-text">○ {{ $t("farmScreen.removePlantNotice") }}</p>
        </div>

        <div v-if="planterState === 'useItem'" class="info-text">
            <p class="ghost-text">○ {{ usedItemNotice }}</p>
            <p class="ghost-text">
                <span class="highlight">○ {{ currentlyUsing }}</span>
            </p>
        </div>

        <div v-if="planterState !== 'inactive'" class="buttons">
            <div class="buttons-cancel">
                <BaseButton
                    @clicked="resetPlanter"
                    :text="$t('misc.cancel')"
                ></BaseButton>
            </div>

            <div v-if="planterState === 'planting'" class="buttons-confirm">
                <BaseButton
                    @clicked="tryPlantCulture"
                    :text="$t('misc.plant')"
                ></BaseButton>
            </div>
            <div
                v-else-if="planterState === 'harvesting'"
                class="buttons-confirm"
            >
                <BaseButton
                    @clicked="tryHarvestCulture"
                    :text="$t('misc.harvest')"
                ></BaseButton>
            </div>
            <div v-else-if="planterState === 'remover'" class="buttons-confirm">
                <BaseButton
                    @clicked="tryRemoveCulture"
                    :text="$t('misc.remove')"
                ></BaseButton>
            </div>
            <div v-if="planterState === 'useItem'" class="buttons-confirm">
                <BaseButton
                    @clicked="tryUseItem"
                    :text="$t('misc.useItem')"
                ></BaseButton>
            </div>
        </div>

        <PlantInfoTooltip v-if="showTooltip"></PlantInfoTooltip>
    </div>
</template>

<script>
import PlantSpot from "@/components/farm/PlantSpot.vue";
import PlantInfoTooltip from "@/components/modal/PlantInfoTooltip.vue";
import { mapActions, mapState } from "vuex";

export default {
    name: "FarmScreen",

    components: {
        PlantSpot,
        PlantInfoTooltip,
    },

    props: {},

    data() {
        return {
            plantStartX: 80,
            plantStartY: 50,
            plantXOffset: 64,
            plantYOffset: 64,

            plantsPerRow: 5,
            plantsRows: 6,
        };
    },

    mounted() {
        const panzoomContainer = this.$refs.game;
        this.initializePanzoom(panzoomContainer);
        this.resetZoom();
    },

    methods: {
        ...mapActions("panzoom", [
            "initializePanzoom",
            "destroyPanzoom",
            "zoomTo",
            "resetZoom",
        ]),
        ...mapActions("site", ["setCurrentModal"]),
        ...mapActions("site/settings", ["playSound"]),
        ...mapActions("planter", [
            "resetPlanter",
            "markPlantIndex",
            "setSelectedPlantIndex",
            "plant",
        ]),
        ...mapActions("contracts", ["callContractFunction"]),

        hasTomato() {
            // console.log(this.balances.seeds["tomato"]);
            return this.balances.seeds["tomato"] > 30;
        },

        getTomatoUrl() {
            const tomatopng = require("../../assets/images/igutest.png");
            return tomatopng;
        },

        getPositionForPlant(row, column) {
            const index = row * this.plantsPerRow + column;
            const [offsetX, offsetY] = [
                index >= 15 ? 480 : 0,
                index >= 15 ? -196 : 0,
            ];
            return {
                x: this.plantStartX + column * this.plantXOffset + offsetX,
                y: this.plantStartY + row * this.plantYOffset + offsetY,
            };
        },

        onPlantSlotClick(index) {
            if (this.planterState === "inactive") {
                setCurrentModal("planter");
                return;
            }

            if (
                ["useItem", "harvesting", "remover", "picker"].includes(
                    this.planterState
                )
            ) {
                this.playSound({ track: "plant_clicked.webm", volume: 0.05 });
                const seed = this.planterSpots[index] === "" ? "marked" : "";
                this.markPlantIndex({ index, seed });
                return;
            }

            this.setSelectedPlantIndex({ index });

            const seedBalance =
                this.balances.seeds[this.selectedSeed] -
                    this.planterBalances[this.selectedSeed] || 0;

            if (seedBalance > 0 && this.pressedKeys["Shift"]) {
                this.playSound({ track: "plant.wav", volume: 0.2 });
                this.plant({
                    seed: this.selectedSeed,
                });
                return;
            } else {
                this.setCurrentModal("seedPicker");
            }
        },

        async tryPlantCulture() {
            const spots = this.planterSpots;
            let selectedSpots = spots.filter((spot) => spot !== "").length;

            if (selectedSpots === 0) {
                this.$store.dispatch("site/notifications/create", {
                    type: "error",
                    message: "No farm spots selected!",
                    id: Date.now(),
                    duration: 5000,
                });

                return;
            }

            await this.callContractFunction({
                contract: "farm",
                functionName: "plantCulture",
                params: {},
            });
        },

        async tryHarvestCulture() {
            const spots = this.planterSpots;
            let selectedSpots = spots.filter((spot) => spot !== "").length;

            if (selectedSpots === 0) {
                this.$store.dispatch("site/notifications/create", {
                    type: "error",
                    message: "No crops selected!",
                    id: Date.now(),
                    duration: 5000,
                });

                return;
            }

            await this.callContractFunction({
                contract: "farm",
                functionName: "harvestCulture",
                params: {},
            });
        },

        async tryRemoveCulture() {
            await this.callContractFunction({
                contract: "farm",
                functionName: "removeCulture",
                params: {},
            });
        },

        async tryUseItem() {
            const spots = this.planterSpots;
            let selectedSpots = spots.filter((spot) => spot !== "").length;

            if (selectedSpots === 0) {
                this.$store.dispatch("site/notifications/create", {
                    type: "error",
                    message: "No crops selected!",
                    id: Date.now(),
                    duration: 5000,
                });

                return;
            }

            await this.callContractFunction({
                contract: "itemController",
                functionName: "useSelectedItem",
                params: { selectedItem: this.selectedConsumable },
            });
        },
    },

    beforeDestroy() {
        this.destroyPanzoom();
        this.resetPlanter();
        this.resetZoom();
    },

    computed: {
        ...mapState("user", ["balances", "farmPlots"]),
        ...mapState("planter", [
            "planterState",
            "planterSpots",
            "selectedPlant",
            "selectedSeed",
            "planterBalances",
            "selectedConsumable",
            "showTooltip",
        ]),
        ...mapState("content", ["itemsData"]),
        ...mapState("site/settings", ["pressedKeys"]),

        selectedPlantsCount() {
            return this.planterSpots.filter((spot) => spot !== "").length;
        },

        selectedConsumableUsage() {
            const itemData = this.itemsData[this.selectedConsumable];
            const plantsSelected = this.selectedPlantsCount;
            const uses = itemData.uses;

            return Math.ceil(plantsSelected / uses);
        },

        currentlyUsing() {
            const on = this.$t("misc.on").toLowerCase();
            const plants = this.$t("misc.plants").toLowerCase();
            const currentlyUsing = this.$t("screens.farm.currentlyUsing");
            const usedItem = this.$t(`items.${this.selectedConsumable}`);

            return `${currentlyUsing} ${this.selectedConsumableUsage} x ${usedItem} ${on} ${this.selectedPlantsCount} ${plants}`;
        },

        usedItemNotice() {
            const usedItem = this.$t(`items.${this.selectedConsumable}`);

            return this.$t("screens.farm.usedItemNotice", { item: usedItem });
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.frame-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 0.25rem;
}
.game-frame {
    width: 100%;
    height: 100%;
    min-width: 980px;
    min-height: 480px;

    overflow: hidden;
    border-radius: 0.25rem;

    &-farm {
        border-image: url("../../../public/images/itemBg.png") 6 fill repeat;
        border-image-width: 1rem;
        border-image-outset: 0.5rem;

        z-index: 1;

        position: absolute;
        top: calc(50% - 240px);
        left: calc(50% - 480px);
        width: 960px;
        height: 480px;
    }
}

.buttons {
    z-index: 100;
    display: flex;
    gap: 2rem;
    position: absolute;
    bottom: 2rem;
    width: 50%;
    left: 25%;
    height: 4rem;

    @media screen and (max-width: 512px) {
        width: 90%;
        left: 5%;
        gap: 1rem;
    }

    > * {
        width: 100%;
        height: 4rem;
    }
}

.info-text {
    z-index: 100;
    position: absolute;
    left: 2rem;
    top: 1rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    pointer-events: none;
    user-select: none;

    @media screen and (max-width: 512px) {
        width: 90%;
        left: 5%;
    }

    .ghost-text {
        width: 100%;
        padding: 0.4rem;
        text-align: start;
        font-size: 2.25rem;
        color: #fff;
        text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
        line-height: 1.5rem;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 0.25rem;

        font-weight: 400;
        letter-spacing: 0.05rem;

        @media screen and (max-width: 512px) {
            font-size: 1.5rem;
            padding: 0.25rem;
            line-height: 1.25rem;
        }
    }
}
</style>
