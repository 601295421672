<template>
    <div class="base-modal">
        <div @click="$emit('close')" class="modal-wrapper"></div>
        <div
            ref="container"
            :style="getStyle"
            class="modal bounce-enter-active"
        >
            <div @click="$emit('close')" class="modal-close"></div>
            <div class="modal-header-row">
                <div class="modal-header">
                    <Header :text="title.toUpperCase()"></Header>
                </div>
            </div>

            <div class="modal-content">
                <slot name="content"></slot>
            </div>

            <div class="modal-footer"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseModal",

    components: {},
    props: {
        width: {
            type: Number,
            default: 32,
        },
        height: {
            type: Number,
            default: 32,
        },
        maxWidth: {
            type: Number,
            default: 9999,
        },
        maxHeight: {
            type: Number,
            default: 9999,
        },
        title: {
            type: String,
            default: "",
        },
        visible: {
            type: Boolean,
            default: false,
        },

        updateKey: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            showWrapper: false,
            initialized: false,

            refresh: 0,
        };
    },

    mounted() {
        this.initialized = true;
    },

    watch: {
        updateKey() {
            this.initialized = false;
            this.refresh++;

            setTimeout(() => {
                this.initialized = true;
            });
        },
    },

    computed: {
        getStyle() {
            if (!this.initialized) {
                return {
                    visibility: "hidden",
                };
            }

            const modalContainer = this.$refs.container;
            let width = Math.max(this.width, modalContainer.offsetWidth);
            let height = Math.max(
                this.height * 0.75,
                modalContainer.offsetHeight
            );

            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            if (width >= windowWidth * 0.9) {
                width = windowWidth * 0.85;
            }

            if (height >= windowHeight) {
                height = windowHeight * 0.8;
            }

            this.refresh;

            return {
                width: `${width}px`,
                maxHeight: `${this.maxHeight}px`,
                maxWidth: `${this.maxWidth}px`,
            };
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.base-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    z-index: 10000;

    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    &-row {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 1rem;
    }
}
.modal {
    position: fixed;
    z-index: 10000;

    border-image: url("../../../public/images/modalBg.png") 6 fill repeat;
    /*   border-image: url(http://www.css3.info/wp-content/uploads/2007/09/border.png) 25 25 25 25 fill round; */
    border-image-width: 1rem;
    box-shadow: 4px 4px 32px #000000;

    padding: 2rem;

    &-close {
        width: 3rem;
        height: 3rem;
        position: absolute;
        right: -3rem;
        top: 2rem;
        background: url("../../../public/images/modalClose.png");
        background-size: 100% 100%;
        cursor: pointer;

        &:hover {
            filter: brightness(1.2);
        }

        @media screen and (max-width: 512px) {
            top: -3rem;
            right: 0.8rem;
        }
    }
    &-header-row {
        width: 100%;
        height: 3.5rem;
        z-index: 10001;

        position: absolute;
        top: -1rem;
        left: 0;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-header {
        min-width: 16rem;
        height: 100%;
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 10000;
        transition: none !important;
        background-color: rgba(0, 0, 0, 0.75);
        backdrop-filter: blur(1px);
    }

    &-content {
        margin-top: 1.5rem;
        text-align: center;
        height: calc(100% - 4rem);
        width: 100%;
    }
}

.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
    0% {
        transform: scale(0);
        opacity: 0.75;
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
</style>
