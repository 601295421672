import farmModule from "./farm";
import yieldModule from "./yield";
import profileModule from "./profile";
import relayerModule from "./relayer";
import seedVendorModule from "./seedVendor";
import cropsModule from "./crops";
import faucetModule from "./faucet";
import pointsModule from "./points";
import itemsModule from "./items";
import badgeModule from "./badge";
import itemController from "./itemController";
import sageModule from "./sage";
import dataAggregatorModule from "./dataAggregator";
import tokenReaderModule from "./tokenReader";
import kickstarterModule from "./kickstarter";

const state = {
    initialized: false,
};

const mutations = {};

const allowedChainIds = [81457];

const actions = {
    async boot({ dispatch }) {
        await dispatch("contracts/relayer/boot", {}, { root: true });
    },

    async callContractFunction(
        { rootState, dispatch, commit },
        { contract, functionName, params }
    ) {
        const { signer } = rootState.site;
        const chainId = Number(
            await window.ethereum.request({ method: "eth_chainId" })
        );

        if (!contract || !functionName) return;
        if (!signer) {
            await dispatch(
                "site/connectWallet",
                { forceSigner: true },
                { root: true }
            );
        }

        try {
            this.dispatch(`contracts/${contract}/${functionName}`, params, {
                root: true,
            });
        } catch (error) {}
    },
};

const getters = {};

const contractModule = {
    state: () => state,
    mutations,
    getters,
    actions,
    modules: {
        farm: farmModule,
        yield: yieldModule,
        profile: profileModule,
        relayer: relayerModule,
        seedVendor: seedVendorModule,
        crops: cropsModule,
        faucet: faucetModule,
        points: pointsModule,
        items: itemsModule,
        itemController: itemController,
        sage: sageModule,
        dataAggregator: dataAggregatorModule,
        badge: badgeModule,
        tokenReader: tokenReaderModule,
        kickstarter: kickstarterModule,
    },
    namespaced: true,
};

export default contractModule;
