import { ethers } from "ethers";

const state = () => ({
    badgeContract: null,
});

const mutations = {
    initializeContract(state, contract) {
        state.badgeContract = contract;
    },
};

const actions = {
    boot({ commit, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/badge.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(
            contractAddress,
            abi,
            signer || defaultSigner
        );

        commit("initializeContract", contract);
    },

    async getSeedsBalance({ state, rootState }) {},
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};
