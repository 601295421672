<template>
    <div class="login-page">
        <div class="login-page-image"></div>
        <div class="login-page-cover"></div>

        <div class="login-page-container" :class="[isOnBlast ? '' : 'small']">
            <div class="login-page-container-content">
                <div class="content-wrapper">
                    <template v-if="!isOnBlast">
                        <div class="login-page-container-logo">
                            <img src="../assets/logo.png" />
                        </div>
                        <br />
                        <p class="small-text">
                            The current network is not supported, <br />
                            please connect to
                            <span class="highlight">Blast Mainnet!</span>
                        </p>
                        <BaseButton
                            @clicked="tryChangeNetwork"
                            style="width: 80%; height: 3rem"
                            :text="'Connect to Blast'"
                        ></BaseButton>
                    </template>
                    <template v-if="isNewUser">
                        <div class="login-page-container-logo">
                            <img src="../assets/logo.png" />
                        </div>

                        <p class="big-text">
                            Welcome to the leading adventuring & farming game<br />
                            on <span class="highlight">Blast</span>!
                        </p>

                        <div class="helpful-links">
                            <div class="divider"></div>
                            <TwitterIcon title="Official Twitter"></TwitterIcon>
                            <DiscordIcon title="Discord Group"></DiscordIcon>
                            <GitbookIcon
                                title="Official Documentation"
                            ></GitbookIcon>
                            <div class="divider"></div>
                        </div>

                        <div class="content-buttons">
                            <BaseButton
                                @clicked="tryConnectWallet"
                                :text="'Connect Wallet'"
                            ></BaseButton>
                        </div>
                    </template>
                    <template v-if="!isNewUser && isUnregisteredUser">
                        <div class="register-container">
                            <p
                                class="big-text"
                                style="font-size: 3rem !important"
                            >
                                Create your profile!
                            </p>
                            <div
                                @click="setCurrentModal('avatarPicker')"
                                title="Profile Picture"
                                class="login-picture-box"
                                style="
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                "
                            >
                                <img
                                    width="96px"
                                    src="@/assets/avatars/1001.png"
                                />
                            </div>
                            <div class="login-input">
                                <p class="small-text">Username</p>
                                <BaseInput
                                    :placeholder="'Max 32 characters'"
                                ></BaseInput>
                            </div>
                            <div class="login-input">
                                <p class="small-text">Referral</p>
                                <BaseInput
                                    :placeholder="'Referall Address (optional)'"
                                ></BaseInput>
                            </div>
                            <br />
                            <div class="content-buttons">
                                <BaseButton
                                    @clicked="tryConnectWallet"
                                    :text="'Create Account'"
                                ></BaseButton>
                            </div>
                            <br />
                            <div class="helpful-links stick-bottom">
                                <div class="divider"></div>
                                <TwitterIcon
                                    title="Official Twitter"
                                ></TwitterIcon>
                                <DiscordIcon
                                    title="Discord Group"
                                ></DiscordIcon>
                                <GitbookIcon
                                    title="Official Documentation"
                                ></GitbookIcon>
                                <div class="divider"></div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import DiscordIcon from "@/components/icons/DiscordIcon.vue";
import TwitterIcon from "@/components/icons/TwitterIcon.vue";
import GitbookIcon from "@/components/icons/GitbookIcon.vue";

export default {
    name: "LoginPage",

    components: { DiscordIcon, TwitterIcon, GitbookIcon },

    props: {},

    data() {
        return {};
    },

    mounted() {},

    beforeDestroy() {},

    methods: {
        ...mapActions("site", [
            "connectWallet",
            "setCurrentModal",
            "requestChangeNetwork",
        ]),

        tryConnectWallet() {
            this.connectWallet({ forceSigner: true });
        },

        tryChangeNetwork() {
            this.requestChangeNetwork();
        },
    },

    computed: {
        ...mapGetters("user", ["isNewUser", "isUnregisteredUser", "isOnBlast"]),

        showRegisterScreen() {
            return this.isNewUser;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.login-page {
    width: 100%;
    height: 97.5%;

    display: flex;
    justify-content: center;
    align-items: center;

    &-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: url("../../public/images/overworld.png") no-repeat center
            center fixed;
        background-size: cover;

        filter: blur(4px);
        z-index: 5;
    }

    &-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.25);

        z-index: 10;
    }

    &-container {
        position: relative;
        width: max(40rem, 35%);
        z-index: 100;
        height: 80%;

        &.small {
            width: max(30rem, 35%);
            height: 60%;
        }

        @media screen and (max-width: 512px) {
            width: 90%;
        }

        &-logo {
            height: 8rem;

            @media screen and (max-width: 768px) {
                height: 8rem;

                width: 60%;
            }

            @media screen and (max-width: 512px) {
                height: 6rem;
                width: 100%;
            }

            img {
                height: 100%;
            }
        }

        &-content {
            position: relative;
            width: 100%;
            height: 100%;
            border-image: url("../../public/images/modalBg.png") 6 fill repeat;
            /*   border-image: url(http://www.css3.info/wp-content/uploads/2007/09/border.png) 25 25 25 25 fill round; */
            border-image-width: 1rem;
            box-shadow: 4px 4px 32px #0000006c;

            display: flex;
            justify-content: center;
            align-items: center;

            &::before {
                background-image: url("../assets/cornerLeftTop.png"),
                    url("../assets/cornerRightTop.png"),
                    url("../assets/cornerLeftBottom.png"),
                    url("../assets/cornerRightBottom.png");
                background-position: 0 0, 100% 0, 0 100%, 100% 100%;
                background-repeat: no-repeat;
                content: "";
                display: block;
                height: 108%;
                image-rendering: pixelated;
                top: -3.75%;
                left: -4%;
                pointer-events: none;
                position: absolute;
                width: 108%;
            }
        }
    }
}

.video-box {
    width: 75%;
    height: 20rem !important;

    @media screen and (max-width: 512px) {
        width: 90%;
        height: 90%;
        height: 16rem !important;
    }

    iframe {
        width: 85%;
        height: 85%;
        border-radius: 0.25rem;
    }
}

.content-buttons {
    width: 50%;
    height: 3.5rem;
    margin-top: 0.5rem;

    @media screen and (max-width: 512px) {
        width: 90%;
        height: 3rem;
    }
}

.helpful-links {
    width: 75%;
    height: 3rem;

    @media screen and (max-width: 768px) {
        height: 2rem;
    }

    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;

    ::v-deep svg {
        @media screen and (max-width: 768px) {
            width: 2rem;
            height: 2rem;
        }

        @media screen and (max-width: 512px) {
            width: 1.5rem;
            height: 1.5rem;
        }

        path {
            fill: #ffd664 !important;
        }
    }
}

.login {
    &-input {
        width: 50%;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: center;

        flex-direction: column;

        p {
            width: 100%;
        }

        .base-input {
            height: 3rem;
            width: 100%;
        }
    }

    &-picture-box {
        min-width: 8rem;
        min-height: 8rem;
        background: rgba(0, 0, 0, 0.25);
        border-radius: 0.5rem;
        border-image: url("../../public/images/itemBg.png") 6 fill repeat;
        border-image-width: 1rem;

        cursor: pointer;

        &:hover {
            transform: scale(1.05);
        }
    }
}

.register-container {
    width: 75%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.stick-bottom {
    margin-top: auto;
}

.content-wrapper {
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 1rem;
}
</style>
