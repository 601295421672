import Vue from "vue";
import Vuex from "vuex";

import siteModule from "@/store/site";
import userModule from "@/store/user";
import contractModule from "./contracts";
import contentModule from "./content";
import planterModule from "./planter";
import panzoomModule from "./panzoom";
import gachaModule from "./gacha";

import { camelCase, upperFirst } from "lodash";

Vue.use(Vuex);

const store = createRootModule();

export function createRootModule() {
    return new Vuex.Store({
        modules: {
            site: siteModule,
            user: userModule,
            contracts: contractModule,
            content: contentModule,
            planter: planterModule,
            panzoom: panzoomModule,
            gacha: gachaModule,
        },
    });
}

export function isValidMutation(store, mutation) {
    return mutation in store._mutations;
}

export function isValidAction(store, action) {
    return action in store._actions;
}

/**
 * Replaces the state of a module by calling the module's mutations
 *
 * Each mutation must be named something like "set{FieldName}".
 *
 * For example, if a key "account_balance" should be replaced in the "user" state,
 * there should be a mutation "user/setAccountBalance"
 */
export function replaceState(modulePath, data, _module = store, _skipNullKeys) {
    if (typeof data !== "object" || data == null) {
        console.error(
            `Unable to replace state: "data" is not an object (actual: ${data})`
        );
        return;
    }

    for (const key in data) {
        if (_skipNullKeys && !data[key]) {
            continue;
        }
        const mutationName = `${modulePath}/set${upperFirst(camelCase(key))}`;

        if (!isValidMutation(_module, mutationName)) {
            console.warn(`Mutation ${mutationName} does not exist`);
            continue;
        }

        _module.commit(mutationName, data[key], { root: true });
    }
}

/**
 * Upserts the state of a module by calling the module's mutations
 *
 * Each mutation must be named something like "upsert{FieldName}".
 *

 */
export function upsertState(modulePath, data, _module = store) {
    if (typeof data !== "object" || data == null) {
        console.error(
            `Unable to upsert state: "data" is not an object (actual: ${data})`
        );
        return;
    }

    for (const key in data) {
        const mutationName = `${modulePath}/upsert${upperFirst(
            camelCase(key)
        )}`;

        if (!isValidMutation(_module, mutationName)) {
            console.warn(`Mutation ${mutationName} does not exist`);
            continue;
        }

        _module.commit(mutationName, data[key], { root: true });
    }
}

export default store;
