<template>
    <div class="base-button-wrapper">
        <div
            @click="handleClick"
            class="base-button"
            :class="[
                disabled ? 'disabled' : 'active',
                pressed ? 'pressed' : '',
            ]"
        >
            <p class="base-button-text">{{ text }}</p>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "BaseButton",

    components: {},

    props: {
        text: String,
        selected: false,
        disabled: false,
        pressed: true,
    },

    data() {
        return {};
    },

    methods: {
        ...mapActions("site/settings", ["playSound"]),

        handleClick() {
            if (this.disabled) return;

            this.playSound({
                track: "click.wav",
                volume: 0.1,
            });
            this.$emit("clicked");
        },
    },

    computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.base-button {
    width: 100%;
    height: 100%;
    min-width: 6rem;
    min-height: 2rem;

    border-image: url("../../../public/images/clickableButtonBg.png") 8 fill
        repeat;
    border-image-width: 1.25rem;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    user-select: none;

    &-text {
        font-size: 2rem;
        margin-bottom: 0.75rem;

        @media screen and (max-width: 512px) {
            font-size: 1.75rem;
            margin-bottom: 0.75rem;
        }
    }

    &.pressed {
        border-image: url("../../../public/images/buttonClicked.png") 8 fill
            repeat;
        border-image-width: 1.25rem;
        filter: brightness(1.2) !important;
    }

    &:active:not(.disabled, .pressed) {
        border-image: url("../../../public/images/buttonClicked.png") 8 fill
            repeat;
        border-image-width: 1.25rem;
    }

    &:active:not(.disabled, .pressed) .base-button-text {
        margin-bottom: 0.25rem;
    }

    &.pressed .base-button-text {
        opacity: 1;
        margin-bottom: 0.25rem;
    }

    &.disabled {
        opacity: 1;
        cursor: not-allowed;
    }

    &.active {
        animation: popup 0.1s ease-in;
    }

    &:hover:not(.disabled) {
        filter: brightness(1.2);
    }
}

.base-button-wrapper {
    width: 100%;
    height: 100%;
}

@keyframes popup {
    0% {
        transform: scale(0.95);
    }
    100% {
        transform: scale(1.05);
    }
}
</style>
