import { ethers } from "ethers";
import { getERC1155FromReceipt } from "@/libs/web3Helper";
import { createNotification } from "@/libs/storeHelper";

const state = () => ({
    seedVendorContract: null,
    lastPackId: 0,
});

const mutations = {
    initializeContract(state, contract) {
        state.seedVendorContract = contract;
    },

    setLastPackId(state, packId) {
        state.lastPackId = packId;
    },
};

const actions = {
    boot({ commit, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/seedVendor.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(
            contractAddress,
            abi,
            signer || defaultSigner
        );

        commit("initializeContract", contract);
    },

    async buySeed(
        { state, dispatch, rootState, commit },
        { amount, type = "basic" }
    ) {
        const contract = state.seedVendorContract;
        const { baseGwei } = rootState.site.settings;
        const { cropsContract } = rootState.contracts.crops;
        const { tokenBalances } = rootState.user;
        const { seedCost, premiumSeedCost } = rootState.gacha;

        if (
            (type === "basic" && tokenBalances < seedCost * amount) ||
            (type === "premium" && tokenBalances < premiumSeedCost * amount)
        ) {
            createNotification({
                dispatch,
                type: "error",
                message: "Insufficient Tokens",
                id: `insufficient-tokens`,
                duration: 5000,
            });

            return;
        }

        let tx;

        if (type === "basic") {
            tx = await contract.buySeed(amount, {
                gasLimit: 150e3 + 25e3 * amount,
                gasPrice: baseGwei,
            });
        } else {
            tx = await contract.buyPremiumSeeds(amount, {
                gasLimit: 150e3 + 25e3 * amount,
                gasPrice: baseGwei,
            });
        }

        commit("setLastPackId", amount);
        commit("gacha/setGachaLoading", true, { root: true });
        commit("gacha/setGachaType", type, { root: true });
        commit("gacha/setGachaLastSize", amount, { root: true });

        dispatch("site/setCurrentModal", "seedShop", { root: true });

        // reset modal
        dispatch("site/setCurrentModal", "gachaResult", { root: true });
        dispatch("site/setCurrentModal", "gachaResult", { root: true });

        createNotification({
            dispatch,
            type: "warn",
            message: `Tx Pending: Buying ${amount} Seeds`,
            id: `${tx.hash}`,
        });

        try {
            const receipt = await tx.wait();

            const seedsFromReceipt = getERC1155FromReceipt(
                receipt,
                cropsContract
            );

            commit("gacha/setGachaLoading", false, { root: true });
            const gachaResult = [];

            seedsFromReceipt.ids.forEach((id, index) => {
                for (let i = 0; i < seedsFromReceipt.values[index]; i++) {
                    gachaResult.push(id);
                }
            });

            dispatch(
                "gacha/drawGacha",
                { result: gachaResult, size: amount },
                { root: true }
            );
            dispatch("user/getBalances", {}, { root: true });
        } catch (error) {
            dispatch("site/setCurrentModal", "gachaResult", { root: true });
            dispatch("site/setCurrentModal", "seedShop", { root: true });
            dispatch("gacha/clearGacha", {}, { root: true });

            createNotification({
                dispatch,
                type: "error",
                message: "Error: Buy Seeds",
                id: `${tx.hash}-error`,
                duration: 5000,
            });
        }

        dispatch("site/notifications/remove", tx.hash, { root: true });
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};
