import { ethers } from "ethers";
import addressManager from "@/libs/addressManager";

const state = () => ({
    relayerContract: null,
});

const mutations = {
    initializeContract(state, contract) {
        state.relayerContract = contract;
    },
};

const actions = {
    async boot({ commit, dispatch, rootState }) {
        const abi = require("@/plugins/artefacts/relayer.json");

        const { defaultSigner, signer } = rootState.site;

        const contract = new ethers.Contract(
            "0x07B26b421C1297CD442dCB4E464fC4EcD977Be60",
            abi,
            defaultSigner || signer
        );

        commit("initializeContract", contract);

        await dispatch("getRelayerContracts");
    },

    async getRelayerContracts({ state, rootState, dispatch }) {
        const contract = state.relayerContract;

        const contractNames = [
            //"farm",
            "yield",
            //"crops",
            //"seedVendor",
            //"profile",
            //"faucet",
            // "points",
            //"items",
            //"itemController",
            //"sage",
            //"badge",
            //"dataAggregator",
            //"tokenReader",
            "kickstarter",
        ];

        const contracts = await contract.getMultipleContracts([
            "yield",
            "kickstarter",
        ]);

        for (let i = 0; i < contracts.length; i++) {
            const contractData = contracts[i];

            const contractName = contractData[0];
            const contractAddress = contractData[1];

            dispatch(
                `contracts/${contractName}/boot`,
                { contractAddress },
                {
                    root: true,
                }
            );
        }

        return contracts;
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};
