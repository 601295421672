<template>
    <div>
        <p class="small-text" v-if="!ready">{{ time }}</p>
        <p class="small-text" v-else>Ready!</p>
    </div>
</template>

<script>
export default {
    name: "BaseTimer",

    components: {},

    props: {
        timeLeft: {
            type: Number,
            default: 0,
        },
        noUpdate: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            timeInterval: null,
            timeElapsed: 0,
            time: "",
            ready: false,
        };
    },

    beforeDestroy() {
        clearInterval(this.timeInterval);
    },

    mounted() {
        this.formatTime(this.timeLeft - this.timeElapsed);
        if (this.noUpdate) return;

        this.timeInterval = setInterval(() => {
            this.timeElapsed += 1000;
            this.formatTime(this.timeLeft - this.timeElapsed);
            if (this.timeLeft - this.timeElapsed <= 0) {
                clearInterval(this.timeInterval);
            }
        }, 1000);
    },

    methods: {
        formatTime(time) {
            const days = Math.floor(time / (1000 * 60 * 60 * 24));
            const hours = Math.floor(
                (time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((time % (1000 * 60)) / 1000);

            if (days > 0) {
                this.time = `${days}d ${hours}h ${minutes}m ${seconds}s`;
                return;
            }

            if (hours > 0) {
                this.time = `${hours}h ${minutes}m ${seconds}s`;
                return;
            }

            if (minutes > 0) {
                this.time = `${minutes}m ${seconds}s`;
                return;
            }

            if (seconds > 0) {
                this.time = `${seconds}s`;
                return;
            }

            this.time = "";
            this.ready = true;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
