import { formatNumber } from "@/libs/kiloNumbers";
import { ZERO_ADDRESS } from "@/libs/storeHelper";
import Vue from "vue";

const allowedChainIds = [81457];

const state = {
    storedAccount: localStorage.getItem("cv:stored_account") || ZERO_ADDRESS,
    account: localStorage.getItem("cv:stored_account") || ZERO_ADDRESS,
    chainId: 0,

    name: "",
    exp: 0,
    farmLevel: 0,
    pendingRewards: 0,
    profileHarvestBonus: 0,
    lockedRatio: 0.1,

    referral: "",
    profilePictureId: 0,

    claimedSage: false,

    farmPlots: new Array(30).fill({}),

    tokenBalances: {
        lockedYield: 0,
        yield: 0,
        rawYield: 0,
        rawLockedYield: 0,
    },

    balances: {
        seeds: {},
        crops: {},
        items: {},
    },

    /// Seed Buy

    freeSeedRolls: 0,
    freePremiumSeedRolls: 0,

    basicCommitData: { commitBlock: 0 },
    premiumCommitData: { commitBlock: 0 },

    kickstarterTokens: 0,
    kickstarterClaimed: false,

    airdropData: {
        farmLevel: 0,
        lockedBalance: 0,
        farmMission: false,
        cropsMission: false,
        packsMission: false,
        gnomeMission: false,
        inviteData: [],
        invited: 0,
        points: 0,
        totalTokens: 0,
        finalPoints: 0,
    },
};

const mutations = {
    setAccount(state, account) {
        state.account = account;

        if (state.storedAccount !== account) {
            state.storedAccount = account;
            localStorage.setItem("cv:stored_account", account);
        }
    },

    setChainId(state, chainId) {
        state.chainId = chainId;
    },

    setPendingRewards(state, pendingRewards) {
        state.pendingRewards = formatNumber(pendingRewards);
    },

    setBalance(state, { token, value }) {
        Vue.set(state.tokenBalances, token, value);
    },

    setSeedBalances(state, balances) {
        state.balances.seeds = balances;
    },

    setCropsBalances(state, balances) {
        state.balances.crops = balances;
    },

    setItemsBalances(state, balances) {
        state.balances.items = balances;
    },

    setLockedRatio(state, lockedRatio) {
        state.lockedRatio = lockedRatio;
    },

    setUserProfile(state, data) {
        state.farmLevel = Number(data.farmLevel);
        state.exp = Number(data.exp);
        state.profileHarvestBonus = Number(data.profileHarvestBonus);
        state.referral = data.referral;
        state.name = data.playerName;
        state.freeSeedRolls = Number(data.freeSeedRolls);
        state.freePremiumSeedRolls = Number(data.freePremiumSeedRolls);
        state.profilePictureId = Number(data.profilePictureId);
        state.claimedSage = data.claimedSage;

        Vue.set(
            state.tokenBalances,
            "rawLockedYield",
            Number(data.lockedBalance)
        );
        Vue.set(
            state.tokenBalances,
            "lockedYield",
            formatNumber(Number(data.lockedBalance) / 1e18)
        );
    },

    setKickstarterTokens(state, payload) {
        const { tokensPurchased, claimed } = payload;
        state.kickstarterTokens = Number(tokensPurchased);
        state.kickstarterClaimed = claimed;
    },

    setFarmPlots(state, farmPlots) {
        state.farmPlots = farmPlots;
    },

    setMissionInfo(state, missionsInfo) {
        state.farmMission = missionsInfo[0];
        state.cropsMission = missionsInfo[1];
        state.packsMission = missionsInfo[2];
        state.gnomeMission = missionsInfo[3];
    },

    commitTestnetInfo(state, data) {
        state.testnetRegistered = data.registered;
        state.claimedFaucet = data.claimedFaucet;
    },

    setAirdropData(state) {
        const user = state.storedAccount.toLowerCase();
        const airdrops = require("@/plugins/airdrop.json");
        const airdropData = airdrops.find((c) => c.address.toLowerCase() === user);

        if (!airdropData) return;

        const data = {
            farmLevel: airdropData.farmLevel,
            lockedBalance: airdropData.lockedBalance,
            farmMission: airdropData.farmMission,
            cropsMission: airdropData.cropsMission,
            packsMission: airdropData.packsMission,
            gnomeMission: airdropData.gnomeMission,
            invited: airdropData.referees.length,
            points: airdropData.points,
            totalTokens: airdropData.totalTokens,
            finalPoints: airdropData.calculatedPoints,
        };

        state.airdropData = data;
    },
};

const actions = {
    async login({ commit, dispatch }, payload) {
        const { account, chainId } = payload;

        commit("user/setAccount", account, { root: true });
        commit("user/setChainId", chainId, { root: true });
    },

    async getBalances({ commit, state, dispatch, rootState }, payload) {
        const kickstarterUserData = await dispatch(
            "contracts/kickstarter/getContractUserData",
            {},
            { root: true }
        );

        commit("setKickstarterTokens", kickstarterUserData);

        return;

        const { cropTypes, itemTypes } = rootState.content;

        const seedsBalances = await dispatch(
            "contracts/crops/getSeedsBalance",
            {},
            { root: true }
        );
        const cropBalances = await dispatch(
            "contracts/items/getCropsBalance",
            {},
            { root: true }
        );
        const itemsBalances = await dispatch(
            "contracts/items/getItemsBalances",
            {},
            { root: true }
        );
        const yieldBalance = await dispatch(
            "contracts/yield/getYieldBalance",
            {},
            { root: true }
        );

        const userSeedBalances = {};
        seedsBalances.forEach((seed) => {
            const name = cropTypes[seed.tokenId];
            userSeedBalances[name] = seed.balance;
        });

        const userCropBalances = {};
        cropBalances.forEach((crop) => {
            const name = cropTypes[crop.tokenId];
            userCropBalances[name] = crop.balance;
        });

        const userItemBalances = {};
        itemsBalances.forEach((item, index) => {
            const name = itemTypes[index];
            userItemBalances[name] = item.balance;
        });

        const faucetContract = rootState.contracts.faucet.faucetContract;

        commit("setBalance", {
            token: "yield",
            value: formatNumber(yieldBalance),
        });
        commit("setBalance", { token: "rawYield", value: yieldBalance });
        commit("setSeedBalances", userSeedBalances);
        commit("setCropsBalances", userCropBalances);
        commit("setItemsBalances", userItemBalances);
    },

    async getStats({ commit, dispatch }) {
        await dispatch("getBalances", {});
        commit("setAirdropData");

        return;

        try {
            if (!state.account || state.account === ZERO_ADDRESS) return;

            const promises = [];
            promises.push(await dispatch("getBalances", {}));

            const profileData = await dispatch(
                "contracts/dataAggregator/getPlayerProfile",
                {},
                { root: true }
            );

            commit("setUserProfile", profileData);

            const farmerInfo = await dispatch(
                "contracts/farm/getFarmerInfo",
                {},
                { root: true }
            );

            const { farmPlotsData, pendingRewards, lockedRatio } = farmerInfo;
            commit("setFarmPlots", farmPlotsData);
            commit("setPendingRewards", pendingRewards);
            commit("setLockedRatio", lockedRatio);
        } catch (error) {
            console.log(error);
        }
    },
};

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

const getters = {
    isOnBlast(state) {
        return true;// allowedChainIds.includes(state.chainId);
    },

    isLoggedIn(state) {
        return state.account !== "" && state.account !== ZERO_ADDRESS;
    },

    isNewUser(state) {
        const userCookie = getCookie("cookie-user-login-info");
        return !state.account || state.account === ZERO_ADDRESS;
    },

    isUnregisteredUser(state) {
        return false; //state.account && state.account !== ZERO_ADDRESS && !state.name;
    },
};

const userModule = {
    state: () => state,
    mutations,
    getters,
    actions,
    namespaced: true,
};

export default userModule;
