<template>
    <div class="base-input">
        <input :placeholder="placeholder" v-model="text" />
    </div>
</template>

<script>
export default {
    name: "BaseInput",

    components: {},

    props: {
        value: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
    },

    data() {
        return {};
    },

    methods: {},

    computed: {
        text: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("input", newValue);
            },
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.base-input {
    width: 100%;
    height: 100%;
    min-height: 2.5rem;
    max-height: 2.5rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    transition: all 0.1s ease-in;
    cursor: pointer;

    user-select: none;

    border-image: url("../../../public/images/reelBg.png") 6 fill repeat;
    border-image-width: 0.75rem;

    input {
        width: 100%;
        height: 100%;
        min-height: 2rem;

        border: none;
        outline: none;
        background: transparent;

        font-size: 1rem;
        font-weight: 600;
        color: #fff;

        padding: 0rem 0.75rem;

        &::placeholder {
            color: #fff;
            opacity: 0.5;
        }
    }
}
</style>
