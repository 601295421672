import { ethers } from "ethers";
import { getERC1155FromReceipt } from "@/libs/web3Helper";
import { createNotification } from "@/libs/storeHelper";

const state = () => ({
    dataAggregatorContract: null,
});

const mutations = {
    initializeContract(state, contract) {
        state.dataAggregatorContract = contract;
    },
};

const actions = {
    boot({ commit, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/dataAggregator.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(
            contractAddress,
            abi,
            signer || defaultSigner
        );

        commit("initializeContract", contract);
    },

    async getPlayerProfile({ state, rootState }) {
        const contract = state.dataAggregatorContract;
        const user = rootState.user.storedAccount;

        const playerInfo = await contract.getProfile(user);

        return playerInfo;
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};
