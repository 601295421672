<template>
    <div class="base-button-wrapper">
        <p class="tiny-text">
            {{ text }}
            <span class="highlight">
                {{ balance }} {{ currency.toUpperCase() }}</span
            >
        </p>
    </div>
</template>

<script>
import { formatNumber } from "@/libs/kiloNumbers";
import { mapState } from "vuex";

export default {
    name: "BaseButton",

    components: {},

    props: {
        text: {
            type: String,
            default: "",
        },
        currency: {
            type: String,
            default: "yield",
        },
    },

    data() {
        return {};
    },

    methods: {},

    computed: {
        ...mapState("user", ["balances"]),

        balance() {
            return formatNumber(this.balances[this.currency]);
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
