import { ZERO_ADDRESS } from "@/libs/storeHelper";
import { ethers } from "ethers";
import tokens from "@/plugins/tokens.json";
import { formatNumber } from "@/libs/kiloNumbers";

const state = () => ({
    tokenReaderContract: null,
});

const mutations = {
    initializeContract(state, contract) {
        state.tokenReaderContract = contract;
    },
};

const actions = {
    boot({ commit, rootState }, { contractAddress }) {
        const abi = require("@/plugins/artefacts/tokenReader.json");

        const { signer, defaultSigner } = rootState.site;
        const contract = new ethers.Contract(
            contractAddress,
            abi,
            signer || defaultSigner
        );

        commit("initializeContract", contract);
    },

    async getTokensData({ state, rootState }) {
        const contract = state.tokenReaderContract;
        const account = rootState.user.storedAccount;

        if (!account || account === ZERO_ADDRESS) return;

        const tokenAddresses = tokens.map((c) => c.address);

        const data = await contract.readTokensData(tokenAddresses, account);

        const finalData = [];

        for (let i = 0; i < data.length; i++) {
            const amount = Number(data[i].amount);
            finalData.push({
                symbol: tokens[i].symbol,
                address: tokens[i].address,
                logo: tokens[i].logo,
                decimals: tokens[i].decimals,
                name: tokens[i].name,
                balance: amount,
                displayBalance: formatNumber(amount / 1e18, 3),
                allowance: data[i].allowance,
                hasAllowance: data[i].allowance > 0,
            });
        }

        return finalData;
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};
