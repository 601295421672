import Vue from "vue";

const state = () => ({
    notifications: [],

    maxNotifications: 10,
    maxNotificationDuration: 180 * 1000,
});

const mutations = {
    addOrUpdateNotification(state, notification) {
        const index = state.notifications.findIndex(
            (n) => n.id === notification.id
        );

        if (index === -1) {
            if (state.notifications.length >= state.maxNotifications) {
                state.notifications.shift();
            }

            state.notifications.push(notification);

            const { type, duration } = notification;
            setTimeout(() => {
                const index = state.notifications.findIndex(
                    (n) => n.id === notification.id
                );
                state.notifications.splice(index, 1);
            }, duration || state.maxNotificationDuration);
        }

        Vue.set(state.notifications, index, notification);
    },

    removeNotification(state, notifId) {
        const index = state.notifications.findIndex((n) => n.id === notifId);
        state.notifications.splice(index, 1);
    },
};

const actions = {
    async create({ commit, dispatch, state, rootState }, payload) {
        commit("addOrUpdateNotification", payload);
    },

    createNotification({ commit, dispatch, state, rootState }, payload) {
        commit("addOrUpdateNotification", payload);
    },

    createTxNotification({ commit, dispatch, state, rootState }, payload) {
        const { hash, message } = payload;
        const tx = {
            type: "warn",
            message: `Tx: ${message}`,
            id: `${hash}`,
            duration: 30 * 1000,
        };

        commit("addOrUpdateNotification", tx);
    },

    async remove({ commit, dispatch, state, rootState }, payload) {
        commit("removeNotification", payload);
    },

    async removeTxNotification(
        { commit, dispatch, state, rootState },
        payload
    ) {
        const { message, hash } = payload;
        commit("removeNotification", message);
        const tx = {
            type: "success",
            message: message || "",
            id: `${hash}-success`,
            duration: 5000,
        };
        commit("addOrUpdateNotification", tx);
    },
};

const getters = {};

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};
