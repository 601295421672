<template>
    <div class="game-frame" ref="game">
        <div class="game-frame-overworld">
            <img
                style="width: 100%; height: 100%"
                src="../../../public/images/overworld.png"
            />

            <MapBanner
                :position="{
                    x: 1410,
                    y: 380,
                }"
                :size="'big'"
                :text="$t('menu.farm')"
                @clicked="goToRoute('farm')"
            ></MapBanner>

            <MapBanner
                :position="{
                    x: 450,
                    y: 280,
                }"
                :size="'big'"
                :text="$t('menu.house')"
                @clicked="goToRoute('house')"
            ></MapBanner>

            <MapBanner
                :position="{
                    x: 790,
                    y: 560,
                }"
                :text="$t('menu.marketplace')"
                :size="'big'"
                @clicked="goToRoute('marketplace')"
            ></MapBanner>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "OverworldScreen",

    components: {},

    props: {},

    data() {
        return {};
    },

    mounted() {
        const panzoomContainer = this.$refs.game;
        this.initializePanzoom(panzoomContainer);
        this.resetZoom(1);
    },

    beforeDestroy() {
        this.destroyPanzoom();
    },

    methods: {
        ...mapActions("panzoom", [
            "initializePanzoom",
            "destroyPanzoom",
            "resetZoom",
        ]),

        goToRoute(route) {
            this.$router.push({ name: route });
        },

        tryOpenTavern() {
            this.$store.dispatch("site/notifications/create", {
                type: "info",
                message: this.$t("notice.tavernNotAvailable"),
                duration: 5000,
                id: "tavern-not-available",
            });
        },
    },

    computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.game-frame {
    width: 100%;
    height: 100%;

    &-overworld {
        width: 2400px;
        height: 1200px;
        border-image: url("../../../public/images/itemBg.png") 6 fill repeat;
        border-image-width: 1rem;
        border-image-outset: 0.5rem;

        z-index: 1;

        position: absolute;
        top: calc(50% - 600px);
        left: calc(50% - 1200px);
    }
}
</style>
